import { memo, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axiosInstance from "axiosInstance";
import { useLocation, useNavigate } from "react-router-dom";
import { useLoading } from "Context/LoadingContext";
import Form from "react-bootstrap/Form";
import { InitialValues, TermDataType } from "./types";
import toastValidation, {
  successToast,
  warnToast,
} from "constants/toastConfig";
import { convertToMonthYearFormat } from "../../../constants/Constants";
import SubmitBtn from "components/Buttons/Submit";

const TermTypesData = [
  { id: "2", value: "Half Yearly" },
  { id: "4", value: "Quarterly" },
  { id: "12", value: "Monthly" },
];

type Props = {};
const FeesStructureSetting = (props: Props) => {
  const { state } = useLocation();
  const nav = useNavigate();
  const { setLoading } = useLoading();
  const [termType, setTermType] = useState<string>("");
  const termData: TermDataType[] = [];

  const [initialValues, setInitialValues] = useState<InitialValues>({
    //Half Yearly
    h_term1_startDate: "",
    h_term1_dueDate: "",
    h_term1_startMonth: "",
    h_term1_endMonth: "",
    h_term2_startDate: "",
    h_term2_dueDate: "",
    h_term2_startMonth: "",
    h_term2_endMonth: "",
    //Quartly
    q_term1_startDate: "",
    q_term1_dueDate: "",
    q_term1_startMonth: "",
    q_term1_endMonth: "",
    q_term2_startDate: "",
    q_term2_dueDate: "",
    q_term2_startMonth: "",
    q_term2_endMonth: "",
    q_term3_startDate: "",
    q_term3_dueDate: "",
    q_term3_startMonth: "",
    q_term3_endMonth: "",
    q_term4_startDate: "",
    q_term4_dueDate: "",
    q_term4_startMonth: "",
    q_term4_endMonth: "",
    //Monthly
    m_term1_startDate: "",
    m_term1_dueDate: "",
    m_term1_startMonth: "",
    m_term1_endMonth: "",
    m_term2_startDate: "",
    m_term2_dueDate: "",
    m_term2_startMonth: "",
    m_term2_endMonth: "",
    m_term3_startDate: "",
    m_term3_dueDate: "",
    m_term3_startMonth: "",
    m_term3_endMonth: "",
    m_term4_startDate: "",
    m_term4_dueDate: "",
    m_term4_startMonth: "",
    m_term4_endMonth: "",
    m_term5_startDate: "",
    m_term5_dueDate: "",
    m_term5_startMonth: "",
    m_term5_endMonth: "",
    m_term6_startDate: "",
    m_term6_dueDate: "",
    m_term6_startMonth: "",
    m_term6_endMonth: "",
    m_term7_startDate: "",
    m_term7_dueDate: "",
    m_term7_startMonth: "",
    m_term7_endMonth: "",
    m_term8_startDate: "",
    m_term8_dueDate: "",
    m_term8_startMonth: "",
    m_term8_endMonth: "",
    m_term9_startDate: "",
    m_term9_dueDate: "",
    m_term9_startMonth: "",
    m_term9_endMonth: "",
    m_term10_startDate: "",
    m_term10_dueDate: "",
    m_term10_startMonth: "",
    m_term10_endMonth: "",
    m_term11_startDate: "",
    m_term11_dueDate: "",
    m_term11_startMonth: "",
    m_term11_endMonth: "",
    m_term12_startDate: "",
    m_term12_dueDate: "",
    m_term12_startMonth: "",
    m_term12_endMonth: "",
  });
  const [validationSchema, setValidationSchema] = useState<any>();

  const setTermTypeInitValues = (term: string) => {
    if (term === "2") {
      setInitialValues({
        //One
        h_term1_startDate: "",
        h_term1_dueDate: "",
        h_term1_startMonth: "",
        h_term1_endMonth: "",
        //Two
        h_term2_startDate: "",
        h_term2_dueDate: "",
        h_term2_endMonth: "",
        h_term2_startMonth: "",
      });
    } else if (term === "4") {
      setInitialValues({
        //One
        q_term1_startDate: "",
        q_term1_dueDate: "",
        q_term1_startMonth: "",
        q_term1_endMonth: "",
        //Two
        q_term2_startDate: "",
        q_term2_dueDate: "",
        q_term2_startMonth: "",
        q_term2_endMonth: "",
        //Three
        q_term3_startDate: "",
        q_term3_dueDate: "",
        q_term3_startMonth: "",
        q_term3_endMonth: "",
        //Four
        q_term4_startDate: "",
        q_term4_dueDate: "",
        q_term4_startMonth: "",
        q_term4_endMonth: "",
      });
    } else if (term === "12") {
      setInitialValues({
        //One
        m_term1_startDate: "",
        m_term1_dueDate: "",
        m_term1_startMonth: "",
        m_term1_endMonth: "",
        //Two
        m_term2_startDate: "",
        m_term2_dueDate: "",
        m_term2_startMonth: "",
        m_term2_endMonth: "",
        //Three
        m_term3_startDate: "",
        m_term3_dueDate: "",
        m_term3_startMonth: "",
        m_term3_endMonth: "",
        //Four
        m_term4_startDate: "",
        m_term4_dueDate: "",
        m_term4_startMonth: "",
        m_term4_endMonth: "",
        //Five
        m_term5_startDate: "",
        m_term5_dueDate: "",
        m_term5_startMonth: "",
        m_term5_endMonth: "",
        //Six
        m_term6_startDate: "",
        m_term6_dueDate: "",
        m_term6_startMonth: "",
        m_term6_endMonth: "",
        //Seven
        m_term7_startDate: "",
        m_term7_dueDate: "",
        m_term7_startMonth: "",
        m_term7_endMonth: "",
        //Eight
        m_term8_startDate: "",
        m_term8_dueDate: "",
        m_term8_startMonth: "",
        m_term8_endMonth: "",
        //Nine
        m_term9_startDate: "",
        m_term9_dueDate: "",
        m_term9_startMonth: "",
        m_term9_endMonth: "",
        //Ten
        m_term10_startDate: "",
        m_term10_dueDate: "",
        m_term10_startMonth: "",
        m_term10_endMonth: "",
        //Eleven
        m_term11_startDate: "",
        m_term11_dueDate: "",
        m_term11_startMonth: "",
        m_term11_endMonth: "",
        //Twelve
        m_term12_startDate: "",
        m_term12_dueDate: "",
        m_term12_startMonth: "",
        m_term12_endMonth: "",
      });
    }
  };

  const hValidationSchema = Yup.object().shape({
    //One
    h_term1_startDate: Yup.string().required(
      "ALL fields of term 1 is Required"
    ),
    h_term1_dueDate: Yup.string().required("ALL fields of term 1 is Required"),
    h_term1_startMonth: Yup.string().required(
      "ALL fields of term 1 is Required"
    ),
    h_term1_endMonth: Yup.string().required("ALL fields of term 1 is Required"),
    //Two
    h_term2_startDate: Yup.string().required(
      "ALL fields of term 2 is Required"
    ),
    h_term2_dueDate: Yup.string().required("ALL fields of term 2 is Required"),
    h_term2_endMonth: Yup.string().required("ALL fields of term 2 is Required"),
    h_term2_startMonth: Yup.string().required(
      "ALL fields of term 2 is Required"
    ),
  });
  const qValidationSchema = Yup.object().shape({
    //One
    q_term1_startDate: Yup.string().required(
      "ALL fields of term 1 is Required"
    ),
    q_term1_dueDate: Yup.string().required("ALL fields of term 1 is Required"),
    q_term1_startMonth: Yup.string().required(
      "ALL fields of term 1 is Required"
    ),
    q_term1_endMonth: Yup.string().required("ALL fields of term 1 is Required"),
    //Two
    q_term2_startDate: Yup.string().required(
      "ALL fields of term 2 is Required"
    ),
    q_term2_dueDate: Yup.string().required("ALL fields of term 2 is Required"),
    q_term2_startMonth: Yup.string().required(
      "ALL fields of term 2 is Required"
    ),
    q_term2_endMonth: Yup.string().required("ALL fields of term 2 is Required"),
    //Three
    q_term3_startDate: Yup.string().required(
      "ALL fields of term 3 is Required"
    ),
    q_term3_dueDate: Yup.string().required("ALL fields of term 3 is Required"),
    q_term3_startMonth: Yup.string().required(
      "ALL fields of term 3 is Required"
    ),
    q_term3_endMonth: Yup.string().required("ALL fields of term 3 is Required"),
    //Four
    q_term4_startDate: Yup.string().required(
      "ALL fields of term 4 is Required"
    ),
    q_term4_dueDate: Yup.string().required("ALL fields of term 4 is Required"),
    q_term4_startMonth: Yup.string().required(
      "ALL fields of term 4 is Required"
    ),
    q_term4_endMonth: Yup.string().required("ALL fields of term 4 is Required"),
  });
  const mValidationSchema = Yup.object().shape({
    //One
    m_term1_startDate: Yup.string().required(
      "ALL fields of term 1 is Required"
    ),
    m_term1_dueDate: Yup.string().required("ALL fields of term 1 is Required"),
    m_term1_startMonth: Yup.string().required(
      "ALL fields of term 1 is Required"
    ),
    m_term1_endMonth: Yup.string().required("ALL fields of term 1 is Required"),
    //Two
    m_term2_startDate: Yup.string().required(
      "ALL fields of term 2 is Required"
    ),
    m_term2_dueDate: Yup.string().required("ALL fields of term 2 is Required"),
    m_term2_startMonth: Yup.string().required(
      "ALL fields of term 2 is Required"
    ),
    m_term2_endMonth: Yup.string().required("ALL fields of term 2 is Required"),
    //Three
    m_term3_startDate: Yup.string().required(
      "ALL fields of term 3 is Required"
    ),
    m_term3_dueDate: Yup.string().required("ALL fields of term 3 is Required"),
    m_term3_startMonth: Yup.string().required(
      "ALL fields of term 3 is Required"
    ),
    m_term3_endMonth: Yup.string().required("ALL fields of term 3 is Required"),
    //Four
    m_term4_startDate: Yup.string().required(
      "ALL fields of term 4 is Required"
    ),
    m_term4_dueDate: Yup.string().required("ALL fields of term 4 is Required"),
    m_term4_startMonth: Yup.string().required(
      "ALL fields of term 4 is Required"
    ),
    m_term4_endMonth: Yup.string().required("ALL fields of term 4 is Required"),
    //Five
    m_term5_startDate: Yup.string().required(
      "ALL fields of term 5 is Required"
    ),
    m_term5_dueDate: Yup.string().required("ALL fields of term 5 is Required"),
    m_term5_startMonth: Yup.string().required(
      "ALL fields of term 5 is Required"
    ),
    m_term5_endMonth: Yup.string().required("ALL fields of term 5 is Required"),
    //Six
    m_term6_startDate: Yup.string().required(
      "ALL fields of term 6 is Required"
    ),
    m_term6_dueDate: Yup.string().required("ALL fields of term 6 is Required"),
    m_term6_startMonth: Yup.string().required(
      "ALL fields of term 6 is Required"
    ),
    m_term6_endMonth: Yup.string().required("ALL fields of term 6 is Required"),
    //Seven
    m_term7_startDate: Yup.string().required(
      "ALL fields of term 7 is Required"
    ),
    m_term7_dueDate: Yup.string().required("ALL fields of term 7 is Required"),
    m_term7_startMonth: Yup.string().required(
      "ALL fields of term 7 is Required"
    ),
    m_term7_endMonth: Yup.string().required("ALL fields of term 7 is Required"),
    //Eight
    m_term8_startDate: Yup.string().required(
      "ALL fields of term 8 is Required"
    ),
    m_term8_dueDate: Yup.string().required("ALL fields of term 8 is Required"),
    m_term8_startMonth: Yup.string().required(
      "ALL fields of term 8 is Required"
    ),
    m_term8_endMonth: Yup.string().required("ALL fields of term 8 is Required"),
    //Nine
    m_term9_startDate: Yup.string().required(
      "ALL fields of term 9 is Required"
    ),
    m_term9_dueDate: Yup.string().required("ALL fields of term 9 is Required"),
    m_term9_startMonth: Yup.string().required(
      "ALL fields of term 9 is Required"
    ),
    m_term9_endMonth: Yup.string().required("ALL fields of term 9 is Required"),
    //Ten
    m_term10_startDate: Yup.string().required(
      " ALL fields of term 10 is Required"
    ),
    m_term10_dueDate: Yup.string().required(
      " ALL fields of term 10 is Required"
    ),
    m_term10_startMonth: Yup.string().required(
      " ALL fields of term 10 is Required"
    ),
    m_term10_endMonth: Yup.string().required(
      " ALL fields of term 10 is Required"
    ),
    //Eleven
    m_term11_startDate: Yup.string().required(
      " ALL fields of term 11 is Required"
    ),
    m_term11_dueDate: Yup.string().required(
      " ALL fields of term 11 is Required"
    ),
    m_term11_startMonth: Yup.string().required(
      " ALL fields of term 11 is Required"
    ),
    m_term11_endMonth: Yup.string().required(
      " ALL fields of term 11 is Required"
    ),
    //Twelve
    m_term12_startDate: Yup.string().required(
      " ALL fields of term 12 is Required"
    ),
    m_term12_dueDate: Yup.string().required(
      " ALL fields of term 12 is Required"
    ),
    m_term12_startMonth: Yup.string().required(
      " ALL fields of term 12 is Required"
    ),
    m_term12_endMonth: Yup.string().required(
      " ALL fields of term 12 is Required"
    ),
  });
  const formik = useFormik({
    validateOnMount: true,
    initialValues: initialValues,
    enableReinitialize: false,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        if (termType === "") return;
        setLoading(true);
        //loop according to term types and post data from the termData array
        if (termType === "2") {
          termData.push({
            termName: "term1",
            startDate: values.h_term1_startDate,
            dueDate: values.h_term1_dueDate,
            startMonth: values.h_term1_startMonth,
            endMonth: values.h_term1_endMonth,
            feesConfigId: state._id,
          });
          termData.push({
            termName: "term2",
            startDate: values.h_term2_startDate,
            dueDate: values.h_term2_dueDate,
            startMonth: values.h_term2_startMonth,
            endMonth: values.h_term2_endMonth,
            feesConfigId: state._id,
          });
        }
        if (termType === "4") {
          termData.push({
            termName: "term1",
            startDate: values.q_term1_startDate,
            dueDate: values.q_term1_dueDate,
            startMonth: values.q_term1_startMonth,
            endMonth: values.q_term1_endMonth,
            feesConfigId: state._id,
          });
          termData.push({
            termName: "term2",
            startDate: values.q_term2_startDate,
            dueDate: values.q_term2_dueDate,
            startMonth: values.q_term2_startMonth,
            endMonth: values.q_term2_endMonth,
            feesConfigId: state._id,
          });
          termData.push({
            termName: "term3",
            startDate: values.q_term3_startDate,
            dueDate: values.q_term3_dueDate,
            startMonth: values.q_term3_startMonth,
            endMonth: values.q_term3_endMonth,
            feesConfigId: state._id,
          });
          termData.push({
            termName: "term4",
            startDate: values.q_term4_startDate,
            dueDate: values.q_term4_dueDate,
            startMonth: values.q_term4_startMonth,
            endMonth: values.q_term4_endMonth,
            feesConfigId: state._id,
          });
        }
        if (termType === "12") {
          termData.push({
            termName: "term1",
            startDate: values.m_term1_startDate,
            dueDate: values.m_term1_dueDate,
            startMonth: values.m_term1_startMonth,
            endMonth: values.m_term1_endMonth,
            feesConfigId: state._id,
          });
          termData.push({
            termName: "term2",
            startDate: values.m_term2_startDate,
            dueDate: values.m_term2_dueDate,
            startMonth: values.m_term2_startMonth,
            endMonth: values.m_term3_endMonth,
            feesConfigId: state._id,
          });
          termData.push({
            termName: "term3",
            startDate: values.m_term3_startDate,
            dueDate: values.m_term3_dueDate,
            startMonth: values.m_term3_startMonth,
            endMonth: values.m_term3_endMonth,
            feesConfigId: state._id,
          });
          termData.push({
            termName: "term4",
            startDate: values.m_term4_startDate,
            dueDate: values.m_term4_dueDate,
            startMonth: values.m_term4_startMonth,
            endMonth: values.m_term4_endMonth,
            feesConfigId: state._id,
          });
          termData.push({
            termName: "term5",
            startDate: values.m_term5_startDate,
            dueDate: values.m_term5_dueDate,
            startMonth: values.m_term5_startMonth,
            endMonth: values.m_term5_endMonth,
            feesConfigId: state._id,
          });
          termData.push({
            termName: "term6",
            startDate: values.m_term6_startDate,
            dueDate: values.m_term6_dueDate,
            startMonth: values.m_term6_startMonth,
            endMonth: values.m_term6_endMonth,
            feesConfigId: state._id,
          });
          termData.push({
            termName: "term7",
            startDate: values.m_term7_startDate,
            dueDate: values.m_term7_dueDate,
            startMonth: values.m_term7_startMonth,
            endMonth: values.m_term7_endMonth,
            feesConfigId: state._id,
          });
          termData.push({
            termName: "term8",
            startDate: values.m_term8_startDate,
            dueDate: values.m_term8_dueDate,
            startMonth: values.m_term8_startMonth,
            endMonth: values.m_term8_endMonth,
            feesConfigId: state._id,
          });
          termData.push({
            termName: "term9",
            startDate: values.m_term9_startDate,
            dueDate: values.m_term9_dueDate,
            startMonth: values.m_term9_startMonth,
            endMonth: values.m_term9_endMonth,
            feesConfigId: state._id,
          });
          termData.push({
            termName: "term10",
            startDate: values.m_term10_startDate,
            dueDate: values.m_term10_dueDate,
            startMonth: values.m_term10_startMonth,
            endMonth: values.m_term10_endMonth,
            feesConfigId: state._id,
          });
          termData.push({
            termName: "term11",
            startDate: values.m_term11_startDate,
            dueDate: values.m_term11_dueDate,
            startMonth: values.m_term11_startMonth,
            endMonth: values.m_term11_endMonth,
            feesConfigId: state._id,
          });
          termData.push({
            termName: "term12",
            startDate: values.m_term12_startDate,
            dueDate: values.m_term12_dueDate,
            startMonth: values.m_term12_startMonth,
            endMonth: values.m_term12_endMonth,
            feesConfigId: state._id,
          });
        }
        let a: any;
        if (termType === "2") {
          a = hValidateDateRanges();
        }
        if (termType === "4") {
          a = qValidateDateRanges();
        }
        if (termType === "12") {
          a = mValidateDateRanges();
        }
        if (a === "") {
          const res = await axiosInstance.post(
            "/master/addFeesStructureSetting",
            { termData, termType }
          );
          if (res.data === "Created") {
            successToast();
            setLoading(false);
            const item = state;
            nav(
              {
                pathname: `/transportationFeesMain/feesSection/${state.feesType}List`,
                search: `?index=2`,
              },
              { state: item }
            );
          }
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    },
  });

  const hValidateDateRanges = () => {
    const hTerm1StartMonth = new Date(formik.values.h_term1_startMonth as any);
    const hTerm1EndMonth = new Date(formik.values.h_term1_endMonth as any);
    const hTerm2StartMonth = new Date(formik.values.h_term2_startMonth as any);
    const hTerm2EndMonth = new Date(formik.values.h_term2_endMonth as any);

    if (hTerm1EndMonth < hTerm1StartMonth) {
      return "Term 1 End Month must be greater than to Term 1 Start Month";
    }
    if (hTerm2StartMonth < hTerm1EndMonth) {
      return "Term 2 Start Month must be greater than to Term 1 End Month";
    }
    if (hTerm2EndMonth < hTerm2StartMonth) {
      return "Term 2 End Month must be greater than to Term 2 Start Month";
    }

    return "";
  };

  const qValidateDateRanges = () => {
    const qTerm1StartMonth = new Date(formik.values.q_term1_startMonth as any);
    const qTerm1EndMonth = new Date(formik.values.q_term1_endMonth as any);
    const qTerm2StartMonth = new Date(formik.values.q_term2_startMonth as any);
    const qTerm2EndMonth = new Date(formik.values.q_term2_endMonth as any);
    const qTerm3StartMonth = new Date(formik.values.q_term3_startMonth as any);
    const qTerm3EndMonth = new Date(formik.values.q_term3_endMonth as any);
    const qTerm4StartMonth = new Date(formik.values.q_term4_startMonth as any);
    const qTerm4EndMonth = new Date(formik.values.q_term4_endMonth as any);

    if (qTerm1EndMonth < qTerm1StartMonth) {
      return "Term 1 End Month must be greater than to Term 1 Start Month";
    }
    if (qTerm2StartMonth < qTerm1EndMonth) {
      return "Term 2 Start Month must be greater than to Term 1 End Month";
    }
    if (qTerm2EndMonth < qTerm2StartMonth) {
      return "Term 2 End Month must be greater than to Term 2 Start Month";
    }
    if (qTerm3StartMonth < qTerm2EndMonth) {
      return "Term 3 Start Month must be greater than to Term 2 End Month";
    }
    if (qTerm3EndMonth < qTerm3StartMonth) {
      return "Term 3 End Month must be greater than to Term 3 Start Month";
    }
    if (qTerm4StartMonth < qTerm3EndMonth) {
      return "Term 4 Start Month must be greater than to Term 3 End Month";
    }
    if (qTerm4EndMonth < qTerm4StartMonth) {
      return "Term 4 End Month must be greater than to Term 4 Start Month";
    }

    return "";
  };

  const mValidateDateRanges = () => {
    const mTerm1StartMonth = new Date(formik.values.m_term1_startMonth as any);
    const mTerm1EndMonth = new Date(formik.values.m_term1_endMonth as any);
    const mTerm2StartMonth = new Date(formik.values.m_term2_startMonth as any);
    const mTerm2EndMonth = new Date(formik.values.m_term2_endMonth as any);
    const mTerm3StartMonth = new Date(formik.values.m_term3_startMonth as any);
    const mTerm3EndMonth = new Date(formik.values.m_term3_endMonth as any);
    const mTerm4StartMonth = new Date(formik.values.m_term4_startMonth as any);
    const mTerm4EndMonth = new Date(formik.values.m_term4_endMonth as any);
    const mTerm5StartMonth = new Date(formik.values.m_term5_startMonth as any);
    const mTerm5EndMonth = new Date(formik.values.m_term5_endMonth as any);
    const mTerm6StartMonth = new Date(formik.values.m_term6_startMonth as any);
    const mTerm6EndMonth = new Date(formik.values.m_term6_endMonth as any);
    const mTerm7StartMonth = new Date(formik.values.m_term7_startMonth as any);
    const mTerm7EndMonth = new Date(formik.values.m_term7_endMonth as any);
    const mTerm8StartMonth = new Date(formik.values.m_term8_startMonth as any);
    const mTerm8EndMonth = new Date(formik.values.m_term8_endMonth as any);
    const mTerm9StartMonth = new Date(formik.values.m_term9_startMonth as any);
    const mTerm9EndMonth = new Date(formik.values.m_term9_endMonth as any);
    const mTerm10StartMonth = new Date(
      formik.values.m_term10_startMonth as any
    );
    const mTerm10EndMonth = new Date(formik.values.m_term10_endMonth as any);
    const mTerm11StartMonth = new Date(
      formik.values.m_term11_startMonth as any
    );
    const mTerm11EndMonth = new Date(formik.values.m_term11_endMonth as any);
    const mTerm12StartMonth = new Date(
      formik.values.m_term12_startMonth as any
    );
    const mTerm12EndMonth = new Date(formik.values.m_term12_endMonth as any);

    if (mTerm1EndMonth < mTerm1StartMonth) {
      return "Term 1 End Month must be greater than to Term 1 Start Month";
    }
    if (mTerm2StartMonth < mTerm1EndMonth) {
      return "Term 2 Start Month must be greater than to Term 1 End Month";
    }
    if (mTerm2EndMonth < mTerm2StartMonth) {
      return "Term 2 End Month must be greater than to Term 2 Start Month";
    }
    if (mTerm3StartMonth < mTerm2EndMonth) {
      return "Term 3 Start Month must be greater than to Term 2 End Month";
    }
    if (mTerm3EndMonth < mTerm3StartMonth) {
      return "Term 3 End Month must be greater than to Term 3 Start Month";
    }
    if (mTerm4StartMonth < mTerm3EndMonth) {
      return "Term 4 Start Month must be greater than to Term 3 End Month";
    }
    if (mTerm4EndMonth < mTerm4StartMonth) {
      return "Term 4 End Month must be greater than to Term 4 Start Month";
    }
    if (mTerm5StartMonth < mTerm4EndMonth) {
      return "Term 5 Start Month must be greater than to Term 4 End Month";
    }

    if (mTerm5EndMonth < mTerm5StartMonth) {
      return "Term 5 End Month must be greater than to Term 5 Start Month";
    }
    if (mTerm6StartMonth < mTerm5EndMonth) {
      return "Term 6 Start Month must be greater than to Term 5 End Month";
    }
    if (mTerm6EndMonth < mTerm6StartMonth) {
      return "Term 6 End Month must be greater than to Term 6 Start Month";
    }
    if (mTerm7StartMonth < mTerm6EndMonth) {
      return "Term 7 Start Month must be greater than to Term 6 End Month";
    }
    if (mTerm7EndMonth < mTerm7StartMonth) {
      return "Term 7 End Month must be greater than to Term 7 Start Month";
    }
    if (mTerm8StartMonth < mTerm7EndMonth) {
      return "Term 8 Start Month must be greater than to Term 7 End Month";
    }
    if (mTerm8EndMonth < mTerm8StartMonth) {
      return "Term 8 End Month must be greater than to Term 8 Start Month";
    }
    if (mTerm9StartMonth < mTerm8EndMonth) {
      return "Term 9 Start Month must be greater than to Term 8 End Month";
    }
    if (mTerm9EndMonth < mTerm9StartMonth) {
      return "Term 9 End Month must be greater than to Term 9 Start Month";
    }
    if (mTerm10StartMonth < mTerm9EndMonth) {
      return "Term 10 Start Month must be greater than to Term 9 End Month";
    }
    if (mTerm10EndMonth < mTerm10StartMonth) {
      return "Term 10 End Month must be greater than to Term 10 Start Month";
    }
    if (mTerm11StartMonth < mTerm10EndMonth) {
      return "Term 11 Start Month must be greater than to Term 10 End Month";
    }
    if (mTerm11EndMonth < mTerm11StartMonth) {
      return "Term 11 End Month must be greater than to Term 11 Start Month";
    }
    if (mTerm12StartMonth < mTerm11EndMonth) {
      return "Term 12 Start Month must be greater than to Term 11 End Month";
    }
    if (mTerm12EndMonth < mTerm12StartMonth) {
      return "Term 12 End Month must be greater than to Term 12 Start Month";
    }

    return "";
  };
  const handleHStartMonthChange = (e: any) => {
    const selectedMonth = e.target.value;
    const [year, month] = selectedMonth.split("-").map(Number);

    // Calculate end month of Term 1 (6 months later)
    let endMonthYear = year;
    let endMonthMonth = (month + 5) % 12 || 12;
    if (month + 5 > 12) {
      endMonthYear++;
    }

    // Calculate start month of Term 2 (1 month after Term 1 end)
    let startMonthTerm2Year = endMonthYear;
    let startMonthTerm2Month = (endMonthMonth % 12) + 1 || 1;
    if (endMonthMonth === 12) {
      startMonthTerm2Year++;
    }

    // Calculate end month of Term 2
    let endMonthTerm2Year = startMonthTerm2Year;
    let endMonthTerm2Month = startMonthTerm2Month + 5;
    if (endMonthTerm2Month > 12) {
      endMonthTerm2Year++;
      endMonthTerm2Month -= 12;
    }

    // Format the end month of Term 2
    const formatMonth = (m: any) => (m < 10 ? `0${m}` : `${m}`);
    const endMonthTerm2Formatted = `${endMonthTerm2Year}-${formatMonth(
      endMonthTerm2Month
    )}`;

    formik.setValues({
      ...formik.values,
      h_term1_startMonth: selectedMonth,
      h_term1_endMonth: `${endMonthYear}-${formatMonth(endMonthMonth)}`,
      h_term2_startMonth: `${startMonthTerm2Year}-${formatMonth(
        startMonthTerm2Month
      )}`,
      h_term2_endMonth: endMonthTerm2Formatted,
    });
  };

  const handleQStartMonthChange = (e: any) => {
    const selectedMonth = e.target.value;
    const [year, month] = selectedMonth.split("-").map(Number);

    // Calculate end month of Term 1 (3 months later)
    let endMonthYear = year;
    let endMonthMonth = (month + 2) % 12 || 12;
    if (month + 2 > 12) {
      endMonthYear++;
    }

    // Calculate start month of Term 2 (1 month after Term 1 end)
    let startMonthTerm2Year = endMonthYear;
    let startMonthTerm2Month = (endMonthMonth % 12) + 1 || 1;
    if (endMonthMonth === 12) {
      startMonthTerm2Year++;
    }

    // Calculate end month of Term 2
    let endMonthTerm2Year = startMonthTerm2Year;
    let endMonthTerm2Month = startMonthTerm2Month + 2;
    if (endMonthTerm2Month > 12) {
      endMonthTerm2Year++;
      endMonthTerm2Month -= 12;
    }

    // Format the end month of Term 2
    const formatMonth = (m: any) => (m < 10 ? `0${m}` : `${m}`);
    const endMonthTerm2Formatted = `${endMonthTerm2Year}-${formatMonth(
      endMonthTerm2Month
    )}`;

    // Calculate start month of Term 3 (1 month after Term 1 end)
    let startMonthTerm3Year = endMonthTerm2Year;
    let startMonthTerm3Month = (endMonthTerm2Month % 12) + 1 || 1;
    if (endMonthTerm2Month === 12) {
      startMonthTerm3Year++;
    }

    // Calculate end month of Term 2
    let endMonthTerm3Year = startMonthTerm3Year;
    let endMonthTerm3Month = startMonthTerm3Month + 2;
    if (endMonthTerm3Month > 12) {
      endMonthTerm3Year++;
      endMonthTerm3Month -= 12;
    }

    // Format the end month of Term 2
    const formatMonth3 = (m: any) => (m < 10 ? `0${m}` : `${m}`);
    const endMonthTerm3Formatted = `${endMonthTerm3Year}-${formatMonth(
      endMonthTerm3Month
    )}`;

    // Calculate start month of Term 4 (1 month after Term 1 end)
    let startMonthTerm4Year = endMonthTerm3Year;
    let startMonthTerm4Month = (endMonthTerm3Month % 12) + 1 || 1;
    if (endMonthTerm3Month === 12) {
      startMonthTerm4Year++;
    }

    // Calculate end month of Term 2
    let endMonthTerm4Year = startMonthTerm4Year;
    let endMonthTerm4Month = startMonthTerm4Month + 2;
    if (endMonthTerm4Month > 12) {
      endMonthTerm4Year++;
      endMonthTerm4Month -= 12;
    }

    // Format the end month of Term 2
    const formatMonth4 = (m: any) => (m < 10 ? `0${m}` : `${m}`);
    const endMonthTerm4Formatted = `${endMonthTerm4Year}-${formatMonth(
      endMonthTerm4Month
    )}`;

    formik.setValues({
      ...formik.values,
      q_term1_startMonth: selectedMonth,
      q_term1_endMonth: `${endMonthYear}-${formatMonth(endMonthMonth)}`,
      q_term2_startMonth: `${startMonthTerm2Year}-${formatMonth(
        startMonthTerm2Month
      )}`,
      q_term2_endMonth: endMonthTerm2Formatted,
      q_term3_startMonth: `${startMonthTerm3Year}-${formatMonth3(
        startMonthTerm3Month
      )}`,
      q_term3_endMonth: endMonthTerm3Formatted,
      q_term4_startMonth: `${startMonthTerm4Year}-${formatMonth4(
        startMonthTerm4Month
      )}`,
      q_term4_endMonth: endMonthTerm4Formatted,
    });
  };

  const handleMStartMonthChange = (e: any) => {
    const selectedMonth = e.target.value;
    const [year, month] = selectedMonth.split("-").map(Number);

    // Loop through each term and calculate start and end months
    const updatedState = {} as any;
    let prevEndMonth = selectedMonth; // Initialize with start month of the first term

    // Set the updated state
    for (let i = 1; i <= 12; i++) {
      const termStartMonthKey = `m_term${i}_startMonth`;
      const termEndMonthKey = `m_term${i}_endMonth`;

      // Calculate start month for the current term
      updatedState[termStartMonthKey] = prevEndMonth;

      // Calculate end month for the current term
      let endMonthYear = year + Math.floor((month + i - 1) / 12);
      let endMonthMonth = ((month + i - 1) % 12) + 1;

      // Format the end month
      const formatMonth = (m: any) => (m < 10 ? `0${m}` : `${m}`);
      const endMonthFormatted = `${endMonthYear}-${formatMonth(endMonthMonth)}`;

      // Update previous end month for the next iteration
      prevEndMonth = endMonthFormatted;

      // Update state with calculated end month for the current term
      updatedState[termEndMonthKey] = endMonthFormatted;
    }

    formik.setValues((prevValues) => ({
      ...formik.values,
      ...prevValues,
      ...updatedState,
    }));
  };
  return (
    <>
      <div className="formBg">
        <form onSubmit={formik.handleSubmit}>
          <div>
            <div className="row align-items-center mb-4 py-3">
              <span className="fw-bold col-4 fs-5 ">
                Configure Structure Settings :
              </span>
              <div className="col ">
                <label className=" row align-items-center" htmlFor="termType">
                  {TermTypesData.map((x) => {
                    return (
                      <Form.Check
                        checked={x?.id === termType}
                        type="radio"
                        label={x?.value}
                        id={x.id}
                        key={x.value}
                        className=" col fw-normal "
                        name="termType"
                        onChange={(e) => {
                          setTermType(e.target.id);
                          setTermTypeInitValues(e.target.id);
                          if (e.target.id === "2") {
                            setValidationSchema(hValidationSchema);
                          } else if (e.target.id === "4") {
                            setValidationSchema(qValidationSchema);
                          } else if (e.target.id === "12") {
                            setValidationSchema(mValidationSchema);
                          }
                        }}
                      />
                    );
                  })}
                </label>
              </div>
            </div>
            <div
              className=" row align-items-center fs-5 fw-bolder "
              style={{
                marginBottom: "40px",
                background: "#f2f2f2",
                paddingTop: "10px",
                paddingBottom: "10px",
              }}
            >
              <span className="col">Fill Out Terms :</span>
              <span className="fw-normal col text-end ">
                {`Financial year : [ ${convertToMonthYearFormat(
                  state.startFeesYear
                )} - ${convertToMonthYearFormat(state.endFeesYear)} ]`}
              </span>
            </div>
            {termType === "2" && (
              <>
                <div>
                  <label
                    className=" mb-5 row align-items-center"
                    htmlFor="h_term1"
                  >
                    <span className="col-1 fw-bold">Term 1 :</span>
                    <label htmlFor="h_term1_startMonth" className="col">
                      <input
                        className={`form-control session-date ${
                          formik.errors.h_term1_dueDate && formik.submitCount
                            ? "empty-form"
                            : ""
                        }`}
                        id="h_term1_startMonth"
                        type="month"
                        name="h_term1_startMonth"
                        value={formik.values.h_term1_startMonth}
                        onChange={handleHStartMonthChange}
                      />
                      <span className="top-placeholder">Start Month</span>
                    </label>
                    <label htmlFor="h_term1_endMonth" className="col">
                      <input
                        className={`form-control session-date ${
                          formik.errors.h_term1_dueDate && formik.submitCount
                            ? "empty-form"
                            : ""
                        }`}
                        id="h_term1_endMonth"
                        type="month"
                        name="h_term1_endMonth"
                        value={formik.values.h_term1_endMonth}
                        readOnly
                      />
                      <span className="top-placeholder">End Month</span>
                    </label>
                    <label htmlFor="h_term1_startDate" className="col">
                      <input
                        className={`form-control session-date ${
                          formik.errors.h_term1_startDate && formik.submitCount
                            ? "empty-form"
                            : ""
                        }`}
                        id="h_term1_startDate"
                        name="h_term1_startDate"
                        type="date"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.h_term1_startDate}
                        onKeyDown={(e) => {
                          e.preventDefault();
                        }}
                      />
                      <span className="top-placeholder">Fees Date</span>
                    </label>
                    <label htmlFor="h_term1_dueDate" className="col">
                      <input
                        className={`form-control session-date ${
                          formik.errors.h_term1_dueDate && formik.submitCount
                            ? "empty-form"
                            : ""
                        }`}
                        id="h_term1_dueDate"
                        name="h_term1_dueDate"
                        type="date"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.h_term1_dueDate}
                        onKeyDown={(e) => {
                          e.preventDefault();
                        }}
                      />
                      <span className="top-placeholder">Due date</span>
                    </label>
                  </label>
                  <label
                    className=" mb-3 row align-items-center"
                    htmlFor="h_term2"
                  >
                    <span className="col-1 fw-bold">Term 2 :</span>
                    <label htmlFor="h_term2_startMonth" className="col">
                      <input
                        className={`form-control session-date ${
                          formik.errors.h_term1_dueDate && formik.submitCount
                            ? "empty-form"
                            : ""
                        }`}
                        id="h_term2_startMonth"
                        type="month"
                        name="h_term2_startMonth"
                        value={formik.values.h_term2_startMonth}
                        readOnly
                      />
                      <span className="top-placeholder">Start Month</span>
                    </label>
                    <label htmlFor="h_term2_endMonth" className="col">
                      <input
                        className={`form-control session-date ${
                          formik.errors.h_term1_dueDate && formik.submitCount
                            ? "empty-form"
                            : ""
                        }`}
                        id="h_term2_endMonth"
                        type="month"
                        name="h_term2_endMonth"
                        value={formik.values.h_term2_endMonth}
                        readOnly
                      />
                      <span className="top-placeholder">End Month</span>
                    </label>
                    <label htmlFor="h_term2_startDate" className="col">
                      <input
                        className={`form-control session-date ${
                          formik.errors.h_term2_startDate && formik.submitCount
                            ? "empty-form"
                            : ""
                        }`}
                        id="h_term2_startDate"
                        name="h_term2_startDate"
                        type="date"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.h_term2_startDate}
                        onKeyDown={(e) => {
                          e.preventDefault();
                        }}
                      />
                      <span className="top-placeholder">Fees Date</span>
                    </label>
                    <label htmlFor="h_term2_dueDate" className="col">
                      <input
                        className={`form-control session-date ${
                          formik.errors.h_term2_dueDate && formik.submitCount
                            ? "empty-form"
                            : ""
                        }`}
                        id="h_term2_dueDate"
                        name="h_term2_dueDate"
                        type="date"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.h_term2_dueDate}
                        onKeyDown={(e) => {
                          e.preventDefault();
                        }}
                      />
                      <span className="top-placeholder">Due date</span>
                    </label>
                  </label>
                </div>
              </>
            )}
            {termType === "4" && (
              <div>
                <label
                  className=" mb-5 row align-items-center"
                  htmlFor="q_term1"
                >
                  <span className="col-1 fw-bold">Term 1 :</span>
                  <label htmlFor="q_term1_startMonth" className="col">
                    <input
                      className={`form-control session-date ${
                        formik.errors.q_term1_startMonth && formik.submitCount
                          ? "empty-form"
                          : ""
                      }`}
                      id="q_term1_startMonth"
                      name="q_term1_startMonth"
                      type="month"
                      onBlur={formik.handleBlur}
                      // onChange={formik.handleChange}
                      onChange={handleQStartMonthChange}
                      value={formik.values.q_term1_startMonth as string}
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                    />
                    <span className="top-placeholder">Start Month</span>
                  </label>
                  <label htmlFor="q_term1_endMonth" className="col">
                    <input
                      className={`form-control session-date ${
                        formik.errors.q_term1_endMonth && formik.submitCount
                          ? "empty-form"
                          : ""
                      }`}
                      id="q_term1_endMonth"
                      name="q_term1_endMonth"
                      type="month"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.q_term1_endMonth as string}
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                      readOnly
                    />
                    <span className="top-placeholder">End Month</span>
                  </label>
                  <label htmlFor="q_term1_startDate" className="col">
                    <input
                      className={`form-control session-date ${
                        formik.errors.q_term1_startDate && formik.submitCount
                          ? "empty-form"
                          : ""
                      }`}
                      id="q_term1_startDate"
                      name="q_term1_startDate"
                      type="date"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.q_term1_startDate}
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                    />
                    <span className="top-placeholder">Fees Date</span>
                  </label>
                  <label htmlFor="q_term1_dueDate" className="col">
                    <input
                      className={`form-control session-date ${
                        formik.errors.q_term1_dueDate && formik.submitCount
                          ? "empty-form"
                          : ""
                      }`}
                      id="q_term1_dueDate"
                      name="q_term1_dueDate"
                      type="date"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.q_term1_dueDate}
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                    />
                    <span className="top-placeholder">Due date</span>
                  </label>
                </label>
                <label
                  className=" mb-5 row align-items-center"
                  htmlFor="q_term2"
                >
                  <span className="col-1 fw-bold">Term 2 :</span>
                  <label htmlFor="q_term2_startMonth" className="col">
                    <input
                      className={`form-control session-date ${
                        formik.errors.q_term2_startMonth && formik.submitCount
                          ? "empty-form"
                          : ""
                      }`}
                      id="q_term2_startMonth"
                      name="q_term2_startMonth"
                      type="month"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.q_term2_startMonth as string}
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                      readOnly
                    />
                    <span className="top-placeholder">Start Month</span>
                  </label>
                  <label htmlFor="q_term2_endMonth" className="col">
                    <input
                      className={`form-control session-date ${
                        formik.errors.q_term2_endMonth && formik.submitCount
                          ? "empty-form"
                          : ""
                      }`}
                      id="q_term2_endMonth"
                      name="q_term2_endMonth"
                      type="month"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.q_term2_endMonth as string}
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                      readOnly
                    />
                    <span className="top-placeholder">End Month</span>
                  </label>
                  <label htmlFor="q_term2_startDate" className="col">
                    <input
                      className={`form-control session-date ${
                        formik.errors.q_term2_startDate && formik.submitCount
                          ? "empty-form"
                          : ""
                      }`}
                      id="q_term2_startDate"
                      name="q_term2_startDate"
                      type="date"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.q_term2_startDate}
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                    />
                    <span className="top-placeholder">Fees Date</span>
                  </label>
                  <label htmlFor="q_term2_dueDate" className="col">
                    <input
                      className={`form-control session-date ${
                        formik.errors.q_term2_dueDate && formik.submitCount
                          ? "empty-form"
                          : ""
                      }`}
                      id="q_term2_dueDate"
                      name="q_term2_dueDate"
                      type="date"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.q_term2_dueDate}
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                    />
                    <span className="top-placeholder">Due date</span>
                  </label>
                </label>
                <label
                  className=" mb-5 row align-items-center"
                  htmlFor="q_term3"
                >
                  <span className="col-1 fw-bold">Term 3 :</span>
                  <label htmlFor="q_term3_startMonth" className="col">
                    <input
                      className={`form-control session-date ${
                        formik.errors.q_term3_startMonth && formik.submitCount
                          ? "empty-form"
                          : ""
                      }`}
                      id="q_term3_startMonth"
                      name="q_term3_startMonth"
                      type="month"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.q_term3_startMonth as string}
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                      readOnly
                    />
                    <span className="top-placeholder">Start Month</span>
                  </label>
                  <label htmlFor="q_term3_endMonth" className="col">
                    <input
                      className={`form-control session-date ${
                        formik.errors.q_term3_endMonth && formik.submitCount
                          ? "empty-form"
                          : ""
                      }`}
                      id="q_term3_endMonth"
                      name="q_term3_endMonth"
                      type="month"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.q_term3_endMonth as string}
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                      readOnly
                    />
                    <span className="top-placeholder">End Month</span>
                  </label>
                  <label htmlFor="q_term3_startDate" className="col">
                    <input
                      className={`form-control session-date ${
                        formik.errors.q_term3_startDate && formik.submitCount
                          ? "empty-form"
                          : ""
                      }`}
                      id="q_term3_startDate"
                      name="q_term3_startDate"
                      type="date"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.q_term3_startDate}
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                    />
                    <span className="top-placeholder">Fees Date</span>
                  </label>
                  <label htmlFor="q_term3_dueDate" className="col">
                    <input
                      className={`form-control session-date ${
                        formik.errors.q_term3_dueDate && formik.submitCount
                          ? "empty-form"
                          : ""
                      }`}
                      id="q_term3_dueDate"
                      name="q_term3_dueDate"
                      type="date"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.q_term3_dueDate}
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                    />
                    <span className="top-placeholder">Due date</span>
                  </label>
                </label>
                <label
                  className=" mb-5 row align-items-center"
                  htmlFor="q_term4"
                >
                  <span className="col-1 fw-bold">Term 4 :</span>
                  <label htmlFor="q_term4_startMonth" className="col">
                    <input
                      className={`form-control session-date ${
                        formik.errors.q_term4_startMonth && formik.submitCount
                          ? "empty-form"
                          : ""
                      }`}
                      id="q_term4_startMonth"
                      name="q_term4_startMonth"
                      type="month"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.q_term4_startMonth as string}
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                      readOnly
                    />
                    <span className="top-placeholder">Start Month</span>
                  </label>
                  <label htmlFor="q_term4_endMonth" className="col">
                    <input
                      className={`form-control session-date ${
                        formik.errors.q_term4_endMonth && formik.submitCount
                          ? "empty-form"
                          : ""
                      }`}
                      id="q_term4_endMonth"
                      name="q_term4_endMonth"
                      type="month"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.q_term4_endMonth as string}
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                      readOnly
                    />
                    <span className="top-placeholder">End Month</span>
                  </label>
                  <label htmlFor="q_term4_startDate" className="col">
                    <input
                      className={`form-control session-date ${
                        formik.errors.q_term4_startDate && formik.submitCount
                          ? "empty-form"
                          : ""
                      }`}
                      id="q_term4_startDate"
                      name="q_term4_startDate"
                      type="date"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.q_term4_startDate}
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                    />
                    <span className="top-placeholder">Fees Date</span>
                  </label>
                  <label htmlFor="q_term4_dueDate" className="col">
                    <input
                      className={`form-control session-date ${
                        formik.errors.q_term4_dueDate && formik.submitCount
                          ? "empty-form"
                          : ""
                      }`}
                      id="q_term4_dueDate"
                      name="q_term4_dueDate"
                      type="date"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.q_term4_dueDate}
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                    />
                    <span className="top-placeholder">Due date</span>
                  </label>
                </label>
              </div>
            )}
            {termType === "12" && (
              <div>
                <label
                  className=" mb-5 row align-items-center"
                  htmlFor="m_term1"
                >
                  <span className="col-1 fw-bold">Term 1 :</span>
                  <label htmlFor="m_term1_startMonth" className="col">
                    <input
                      className={`form-control session-date ${
                        formik.errors.m_term1_startMonth && formik.submitCount
                          ? "empty-form"
                          : ""
                      }`}
                      id="m_term1_startMonth"
                      name="m_term1_startMonth"
                      type="month"
                      onBlur={formik.handleBlur}
                      onChange={handleMStartMonthChange}
                      value={formik.values.m_term1_startMonth as string}
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                    />
                    <span className="top-placeholder">Start Month</span>
                  </label>
                  <label htmlFor="m_term1_endMonth" className="col">
                    <input
                      className={`form-control session-date ${
                        formik.errors.m_term1_endMonth && formik.submitCount
                          ? "empty-form"
                          : ""
                      }`}
                      id="m_term1_endMonth"
                      name="m_term1_endMonth"
                      type="month"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.m_term1_endMonth as string}
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                      readOnly
                    />
                    <span className="top-placeholder">End Month</span>
                  </label>
                  <label htmlFor="m_term1_startDate" className="col">
                    <input
                      className={`form-control session-date ${
                        formik.errors.m_term1_startDate && formik.submitCount
                          ? "empty-form"
                          : ""
                      }`}
                      id="m_term1_startDate"
                      name="m_term1_startDate"
                      type="date"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.m_term1_startDate}
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                    />
                    <span className="top-placeholder">Fees Date</span>
                  </label>
                  <label htmlFor="m_term1_dueDate" className="col">
                    <input
                      className={`form-control session-date ${
                        formik.errors.m_term1_dueDate && formik.submitCount
                          ? "empty-form"
                          : ""
                      }`}
                      id="m_term1_dueDate"
                      name="m_term1_dueDate"
                      type="date"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.m_term1_dueDate}
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                    />
                    <span className="top-placeholder">Due date</span>
                  </label>
                </label>
                <label
                  className=" mb-5 row align-items-center"
                  htmlFor="m_term2"
                >
                  <span className="col-1 fw-bold">Term 2 :</span>
                  <label htmlFor="m_term2_startMonth" className="col">
                    <input
                      className={`form-control session-date ${
                        formik.errors.m_term2_startMonth && formik.submitCount
                          ? "empty-form"
                          : ""
                      }`}
                      id="m_term2_startMonth"
                      name="m_term2_startMonth"
                      type="month"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.m_term2_startMonth as string}
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                      readOnly
                    />
                    <span className="top-placeholder">Start Month</span>
                  </label>
                  <label htmlFor="m_term2_endMonth" className="col">
                    <input
                      className={`form-control session-date ${
                        formik.errors.m_term2_endMonth && formik.submitCount
                          ? "empty-form"
                          : ""
                      }`}
                      id="m_term2_endMonth"
                      name="m_term2_endMonth"
                      type="month"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.m_term2_endMonth as string}
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                      readOnly
                    />
                    <span className="top-placeholder">End Month</span>
                  </label>
                  <label htmlFor="m_term2_startDate" className="col">
                    <input
                      className={`form-control session-date ${
                        formik.errors.m_term2_startDate && formik.submitCount
                          ? "empty-form"
                          : ""
                      }`}
                      id="m_term2_startDate"
                      name="m_term2_startDate"
                      type="date"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.m_term2_startDate}
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                    />
                    <span className="top-placeholder">Fees Date</span>
                  </label>
                  <label htmlFor="m_term2_dueDate" className="col">
                    <input
                      className={`form-control session-date ${
                        formik.errors.m_term2_dueDate && formik.submitCount
                          ? "empty-form"
                          : ""
                      }`}
                      id="m_term2_dueDate"
                      name="m_term2_dueDate"
                      type="date"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.m_term2_dueDate}
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                    />
                    <span className="top-placeholder">Due date</span>
                  </label>
                </label>
                <label
                  className=" mb-5 row align-items-center"
                  htmlFor="m_term3"
                >
                  <span className="col-1 fw-bold">Term 3 :</span>
                  <label htmlFor="m_term3_startMonth" className="col">
                    <input
                      className={`form-control session-date ${
                        formik.errors.m_term3_startMonth && formik.submitCount
                          ? "empty-form"
                          : ""
                      }`}
                      id="m_term3_startMonth"
                      name="m_term3_startMonth"
                      type="month"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.m_term3_startMonth as string}
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                      readOnly
                    />
                    <span className="top-placeholder">Start Month</span>
                  </label>
                  <label htmlFor="m_term3_endMonth" className="col">
                    <input
                      className={`form-control session-date ${
                        formik.errors.m_term3_endMonth && formik.submitCount
                          ? "empty-form"
                          : ""
                      }`}
                      id="m_term3_endMonth"
                      name="m_term3_endMonth"
                      type="month"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.m_term3_endMonth as string}
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                      readOnly
                    />
                    <span className="top-placeholder">End Month</span>
                  </label>
                  <label htmlFor="m_term3_startDate" className="col">
                    <input
                      className={`form-control session-date ${
                        formik.errors.m_term3_startDate && formik.submitCount
                          ? "empty-form"
                          : ""
                      }`}
                      id="m_term3_startDate"
                      name="m_term3_startDate"
                      type="date"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.m_term3_startDate}
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                    />
                    <span className="top-placeholder">Fees Date</span>
                  </label>
                  <label htmlFor="m_term3_dueDate" className="col">
                    <input
                      className={`form-control session-date ${
                        formik.errors.m_term3_dueDate && formik.submitCount
                          ? "empty-form"
                          : ""
                      }`}
                      id="m_term3_dueDate"
                      name="m_term3_dueDate"
                      type="date"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.m_term3_dueDate}
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                    />
                    <span className="top-placeholder">Due date</span>
                  </label>
                </label>
                <label
                  className=" mb-5 row align-items-center"
                  htmlFor="m_term4"
                >
                  <span className="col-1 fw-bold">Term 4 :</span>
                  <label htmlFor="m_term4_startMonth" className="col">
                    <input
                      className={`form-control session-date ${
                        formik.errors.m_term4_startMonth && formik.submitCount
                          ? "empty-form"
                          : ""
                      }`}
                      id="m_term4_startMonth"
                      name="m_term4_startMonth"
                      type="month"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.m_term4_startMonth as string}
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                      readOnly
                    />
                    <span className="top-placeholder">Start Month</span>
                  </label>
                  <label htmlFor="m_term4_endMonth" className="col">
                    <input
                      className={`form-control session-date ${
                        formik.errors.m_term4_endMonth && formik.submitCount
                          ? "empty-form"
                          : ""
                      }`}
                      id="m_term4_endMonth"
                      name="m_term4_endMonth"
                      type="month"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.m_term4_endMonth as string}
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                      readOnly
                    />
                    <span className="top-placeholder">End Month</span>
                  </label>
                  <label htmlFor="m_term4_startDate" className="col">
                    <input
                      className={`form-control session-date ${
                        formik.errors.m_term4_startDate && formik.submitCount
                          ? "empty-form"
                          : ""
                      }`}
                      id="m_term4_startDate"
                      name="m_term4_startDate"
                      type="date"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.m_term4_startDate}
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                    />
                    <span className="top-placeholder">Fees Date</span>
                  </label>
                  <label htmlFor="m_term4_dueDate" className="col">
                    <input
                      className={`form-control session-date ${
                        formik.errors.m_term4_dueDate && formik.submitCount
                          ? "empty-form"
                          : ""
                      }`}
                      id="m_term4_dueDate"
                      name="m_term4_dueDate"
                      type="date"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.m_term4_dueDate}
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                    />
                    <span className="top-placeholder">Due date</span>
                  </label>
                </label>
                <label
                  className=" mb-5 row align-items-center"
                  htmlFor="m_term5"
                >
                  <span className="col-1 fw-bold">Term 5 :</span>
                  <label htmlFor="m_term5_startMonth" className="col">
                    <input
                      className={`form-control session-date ${
                        formik.errors.m_term5_startMonth && formik.submitCount
                          ? "empty-form"
                          : ""
                      }`}
                      id="m_term5_startMonth"
                      name="m_term5_startMonth"
                      type="month"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.m_term5_startMonth as string}
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                      readOnly
                    />
                    <span className="top-placeholder">Start Month</span>
                  </label>
                  <label htmlFor="m_term5_endMonth" className="col">
                    <input
                      className={`form-control session-date ${
                        formik.errors.m_term5_endMonth && formik.submitCount
                          ? "empty-form"
                          : ""
                      }`}
                      id="m_term5_endMonth"
                      name="m_term5_endMonth"
                      type="month"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.m_term5_endMonth as string}
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                      readOnly
                    />
                    <span className="top-placeholder">End Month</span>
                  </label>
                  <label htmlFor="m_term5_startDate" className="col">
                    <input
                      className={`form-control session-date ${
                        formik.errors.m_term5_startDate && formik.submitCount
                          ? "empty-form"
                          : ""
                      }`}
                      id="m_term5_startDate"
                      name="m_term5_startDate"
                      type="date"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.m_term5_startDate}
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                    />
                    <span className="top-placeholder">Fees Date</span>
                  </label>
                  <label htmlFor="m_term5_dueDate" className="col">
                    <input
                      className={`form-control session-date ${
                        formik.errors.m_term5_dueDate && formik.submitCount
                          ? "empty-form"
                          : ""
                      }`}
                      id="m_term5_dueDate"
                      name="m_term5_dueDate"
                      type="date"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.m_term5_dueDate}
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                    />
                    <span className="top-placeholder">Due date</span>
                  </label>
                </label>
                <label
                  className=" mb-5 row align-items-center"
                  htmlFor="m_term6"
                >
                  <span className="col-1 fw-bold">Term 6 :</span>
                  <label htmlFor="m_term6_startMonth" className="col">
                    <input
                      className={`form-control session-date ${
                        formik.errors.m_term6_startMonth && formik.submitCount
                          ? "empty-form"
                          : ""
                      }`}
                      id="m_term6_startMonth"
                      name="m_term6_startMonth"
                      type="month"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.m_term6_startMonth as string}
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                      readOnly
                    />
                    <span className="top-placeholder">Start Month</span>
                  </label>
                  <label htmlFor="m_term6_endMonth" className="col">
                    <input
                      className={`form-control session-date ${
                        formik.errors.m_term6_endMonth && formik.submitCount
                          ? "empty-form"
                          : ""
                      }`}
                      id="m_term6_endMonth"
                      name="m_term6_endMonth"
                      type="month"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.m_term6_endMonth as string}
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                      readOnly
                    />
                    <span className="top-placeholder">End Month</span>
                  </label>
                  <label htmlFor="m_term6_startDate" className="col">
                    <input
                      className={`form-control session-date ${
                        formik.errors.m_term6_startDate && formik.submitCount
                          ? "empty-form"
                          : ""
                      }`}
                      id="m_term6_startDate"
                      name="m_term6_startDate"
                      type="date"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.m_term6_startDate}
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                    />
                    <span className="top-placeholder">Fees Date</span>
                  </label>
                  <label htmlFor="m_term6_dueDate" className="col">
                    <input
                      className={`form-control session-date ${
                        formik.errors.m_term6_dueDate && formik.submitCount
                          ? "empty-form"
                          : ""
                      }`}
                      id="m_term6_dueDate"
                      name="m_term6_dueDate"
                      type="date"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.m_term6_dueDate}
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                    />
                    <span className="top-placeholder">Due date</span>
                  </label>
                </label>
                <label
                  className=" mb-5 row align-items-center"
                  htmlFor="m_term7"
                >
                  <span className="col-1 fw-bold">Term 7 :</span>
                  <label htmlFor="m_term7_startMonth" className="col">
                    <input
                      className={`form-control session-date ${
                        formik.errors.m_term7_startMonth && formik.submitCount
                          ? "empty-form"
                          : ""
                      }`}
                      id="m_term7_startMonth"
                      name="m_term7_startMonth"
                      type="month"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.m_term7_startMonth as string}
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                      readOnly
                    />
                    <span className="top-placeholder">Start Month</span>
                  </label>
                  <label htmlFor="m_term7_endMonth" className="col">
                    <input
                      className={`form-control session-date ${
                        formik.errors.m_term7_endMonth && formik.submitCount
                          ? "empty-form"
                          : ""
                      }`}
                      id="m_term7_endMonth"
                      name="m_term7_endMonth"
                      type="month"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.m_term7_endMonth as string}
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                      readOnly
                    />
                    <span className="top-placeholder">End Month</span>
                  </label>
                  <label htmlFor="m_term7_startDate" className="col">
                    <input
                      className={`form-control session-date ${
                        formik.errors.m_term7_startDate && formik.submitCount
                          ? "empty-form"
                          : ""
                      }`}
                      id="m_term7_startDate"
                      name="m_term7_startDate"
                      type="date"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.m_term7_startDate}
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                    />
                    <span className="top-placeholder">Fees Date</span>
                  </label>
                  <label htmlFor="m_term7_dueDate" className="col">
                    <input
                      className={`form-control session-date ${
                        formik.errors.m_term7_dueDate && formik.submitCount
                          ? "empty-form"
                          : ""
                      }`}
                      id="m_term7_dueDate"
                      name="m_term7_dueDate"
                      type="date"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.m_term7_dueDate}
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                    />
                    <span className="top-placeholder">Due date</span>
                  </label>
                </label>
                <label
                  className=" mb-5 row align-items-center"
                  htmlFor="m_term8"
                >
                  <span className="col-1 fw-bold">Term 8 :</span>
                  <label htmlFor="m_term8_startMonth" className="col">
                    <input
                      className={`form-control session-date ${
                        formik.errors.m_term8_startMonth && formik.submitCount
                          ? "empty-form"
                          : ""
                      }`}
                      id="m_term8_startMonth"
                      name="m_term8_startMonth"
                      type="month"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.m_term8_startMonth as string}
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                      readOnly
                    />
                    <span className="top-placeholder">Start Month</span>
                  </label>
                  <label htmlFor="m_term8_endMonth" className="col">
                    <input
                      className={`form-control session-date ${
                        formik.errors.m_term8_endMonth && formik.submitCount
                          ? "empty-form"
                          : ""
                      }`}
                      id="m_term8_endMonth"
                      name="m_term8_endMonth"
                      type="month"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.m_term8_endMonth as string}
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                      readOnly
                    />
                    <span className="top-placeholder">End Month</span>
                  </label>
                  <label htmlFor="m_term8_startDate" className="col">
                    <input
                      className={`form-control session-date ${
                        formik.errors.m_term8_startDate && formik.submitCount
                          ? "empty-form"
                          : ""
                      }`}
                      id="m_term8_startDate"
                      name="m_term8_startDate"
                      type="date"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.m_term8_startDate}
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                    />
                    <span className="top-placeholder">Fees Date</span>
                  </label>
                  <label htmlFor="m_term8_dueDate" className="col">
                    <input
                      className={`form-control session-date ${
                        formik.errors.m_term8_dueDate && formik.submitCount
                          ? "empty-form"
                          : ""
                      }`}
                      id="m_term8_dueDate"
                      name="m_term8_dueDate"
                      type="date"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.m_term8_dueDate}
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                    />
                    <span className="top-placeholder">Due date</span>
                  </label>
                </label>
                <label
                  className=" mb-5 row align-items-center"
                  htmlFor="m_term9"
                >
                  <span className="col-1 fw-bold">Term 9 :</span>
                  <label htmlFor="m_term9_startMonth" className="col">
                    <input
                      className={`form-control session-date ${
                        formik.errors.m_term9_startMonth && formik.submitCount
                          ? "empty-form"
                          : ""
                      }`}
                      id="m_term9_startMonth"
                      name="m_term9_startMonth"
                      type="month"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.m_term9_startMonth as string}
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                      readOnly
                    />
                    <span className="top-placeholder">Start Month</span>
                  </label>
                  <label htmlFor="m_term9_endMonth" className="col">
                    <input
                      className={`form-control session-date ${
                        formik.errors.m_term9_endMonth && formik.submitCount
                          ? "empty-form"
                          : ""
                      }`}
                      id="m_term9_endMonth"
                      name="m_term9_endMonth"
                      type="month"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.m_term9_endMonth as string}
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                      readOnly
                    />
                    <span className="top-placeholder">End Month</span>
                  </label>
                  <label htmlFor="m_term9_startDate" className="col">
                    <input
                      className={`form-control session-date ${
                        formik.errors.m_term9_startDate && formik.submitCount
                          ? "empty-form"
                          : ""
                      }`}
                      id="m_term9_startDate"
                      name="m_term9_startDate"
                      type="date"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.m_term9_startDate}
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                    />
                    <span className="top-placeholder">Fees Date</span>
                  </label>
                  <label htmlFor="m_term9_dueDate" className="col">
                    <input
                      className={`form-control session-date ${
                        formik.errors.m_term9_dueDate && formik.submitCount
                          ? "empty-form"
                          : ""
                      }`}
                      id="m_term9_dueDate"
                      name="m_term9_dueDate"
                      type="date"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.m_term9_dueDate}
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                    />
                    <span className="top-placeholder">Due date</span>
                  </label>
                </label>
                <label
                  className=" mb-5 row align-items-center"
                  htmlFor="m_term10"
                >
                  <span className="col-1 fw-bold">Term 10 :</span>
                  <label htmlFor="m_term10_startMonth" className="col">
                    <input
                      className={`form-control session-date ${
                        formik.errors.m_term10_startMonth && formik.submitCount
                          ? "empty-form"
                          : ""
                      }`}
                      id="m_term10_startMonth"
                      name="m_term10_startMonth"
                      type="month"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.m_term10_startMonth as string}
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                      readOnly
                    />
                    <span className="top-placeholder">Start Month</span>
                  </label>
                  <label htmlFor="m_term10_endMonth" className="col">
                    <input
                      className={`form-control session-date ${
                        formik.errors.m_term10_endMonth && formik.submitCount
                          ? "empty-form"
                          : ""
                      }`}
                      id="m_term10_endMonth"
                      name="m_term10_endMonth"
                      type="month"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.m_term10_endMonth as string}
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                      readOnly
                    />
                    <span className="top-placeholder">End Month</span>
                  </label>
                  <label htmlFor="m_term10_startDate" className="col">
                    <input
                      className={`form-control session-date ${
                        formik.errors.m_term10_startDate && formik.submitCount
                          ? "empty-form"
                          : ""
                      }`}
                      id="m_term10_startDate"
                      name="m_term10_startDate"
                      type="date"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.m_term10_startDate}
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                    />
                    <span className="top-placeholder">Fees Date</span>
                  </label>
                  <label htmlFor="m_term10_dueDate" className="col">
                    <input
                      className={`form-control session-date ${
                        formik.errors.m_term10_dueDate && formik.submitCount
                          ? "empty-form"
                          : ""
                      }`}
                      id="m_term10_dueDate"
                      name="m_term10_dueDate"
                      type="date"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.m_term10_dueDate}
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                    />
                    <span className="top-placeholder">Due date</span>
                  </label>
                </label>
                <label
                  className=" mb-5 row align-items-center"
                  htmlFor="m_term11"
                >
                  <span className="col-1 fw-bold">Term 11 :</span>
                  <label htmlFor="m_term11_startMonth" className="col">
                    <input
                      className={`form-control session-date ${
                        formik.errors.m_term11_startMonth && formik.submitCount
                          ? "empty-form"
                          : ""
                      }`}
                      id="m_term11_startMonth"
                      name="m_term11_startMonth"
                      type="month"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.m_term11_startMonth as string}
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                      readOnly
                    />
                    <span className="top-placeholder">Start Month</span>
                  </label>
                  <label htmlFor="m_term11_endMonth" className="col">
                    <input
                      className={`form-control session-date ${
                        formik.errors.m_term11_endMonth && formik.submitCount
                          ? "empty-form"
                          : ""
                      }`}
                      id="m_term11_endMonth"
                      name="m_term11_endMonth"
                      type="month"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.m_term11_endMonth as string}
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                      readOnly
                    />
                    <span className="top-placeholder">End Month</span>
                  </label>
                  <label htmlFor="m_term11_startDate" className="col">
                    <input
                      className={`form-control session-date ${
                        formik.errors.m_term11_startDate && formik.submitCount
                          ? "empty-form"
                          : ""
                      }`}
                      id="m_term11_startDate"
                      name="m_term11_startDate"
                      type="date"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.m_term11_startDate}
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                    />
                    <span className="top-placeholder">Fees Date</span>
                  </label>
                  <label htmlFor="m_term11_dueDate" className="col">
                    <input
                      className={`form-control session-date ${
                        formik.errors.m_term11_dueDate && formik.submitCount
                          ? "empty-form"
                          : ""
                      }`}
                      id="m_term11_dueDate"
                      name="m_term11_dueDate"
                      type="date"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.m_term11_dueDate}
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                    />
                    <span className="top-placeholder">Due date</span>
                  </label>
                </label>
                <label
                  className=" mb-5 row align-items-center"
                  htmlFor="m_term12"
                >
                  <span className="col-1 fw-bold">Term 12 :</span>
                  <label htmlFor="m_term12_startMonth" className="col">
                    <input
                      className={`form-control session-date ${
                        formik.errors.m_term12_startMonth && formik.submitCount
                          ? "empty-form"
                          : ""
                      }`}
                      id="m_term12_startMonth"
                      name="m_term12_startMonth"
                      type="month"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.m_term12_startMonth as string}
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                      readOnly
                    />
                    <span className="top-placeholder">Start Month</span>
                  </label>
                  <label htmlFor="m_term12_endMonth" className="col">
                    <input
                      className={`form-control session-date ${
                        formik.errors.m_term12_endMonth && formik.submitCount
                          ? "empty-form"
                          : ""
                      }`}
                      id="m_term12_endMonth"
                      name="m_term12_endMonth"
                      type="month"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.m_term12_endMonth as string}
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                      readOnly
                    />
                    <span className="top-placeholder">End Month</span>
                  </label>
                  <label htmlFor="m_term12_startDate" className="col">
                    <input
                      className={`form-control session-date ${
                        formik.errors.m_term12_startDate && formik.submitCount
                          ? "empty-form"
                          : ""
                      }`}
                      id="m_term12_startDate"
                      name="m_term12_startDate"
                      type="date"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.m_term12_startDate}
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                    />
                    <span className="top-placeholder">Fees Date</span>
                  </label>
                  <label htmlFor="m_term12_dueDate" className="col">
                    <input
                      className={`form-control session-date ${
                        formik.errors.m_term12_dueDate && formik.submitCount
                          ? "empty-form"
                          : ""
                      }`}
                      id="m_term12_dueDate"
                      name="m_term12_dueDate"
                      type="date"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.m_term12_dueDate}
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                    />
                    <span className="top-placeholder">Due date</span>
                  </label>
                </label>
              </div>
            )}
          </div>
          <div className="col-md-12 mb-3 text-center">
            <SubmitBtn
              errors={formik.errors}
              clickFunc={() => {
                if (termType === "") {
                  warnToast("Structure is Required");
                }
                if (termType === "2") {
                  const a: any = hValidateDateRanges();
                  if (a !== "") {
                    warnToast(String(a));
                  }
                }
                if (termType === "4") {
                  const a: any = qValidateDateRanges();
                  if (a !== "") {
                    warnToast(String(a));
                  }
                }
                if (termType === "12") {
                  const a: any = mValidateDateRanges();
                  if (a !== "") {
                    warnToast(String(a));
                  }
                }
                toastValidation(formik.errors);
              }}
            />
            <button
              className="btn btn-primary"
              onClick={() => {
                nav({
                  pathname: "/transportationFeesMain",
                });
              }}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default memo(FeesStructureSetting);
