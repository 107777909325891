import { useLoading } from "Context/LoadingContext";
import axiosInstance from "axiosInstance";
import React, { useCallback, useEffect, useState } from "react";
import { Button, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import RouteMemberAddForm, {
  pickUpRouteDetailsType,
} from "./RouteMemberAddForm";
import { ReactComponent as Edit } from "assets/images/edit.svg";
import { useFormik } from "formik";
import { successToast } from "constants/toastConfig";
import CancelBtn from "components/Buttons/Cancel";
import { ReactComponent as Done } from "assets/images/circleDone.svg";

type Props = {
  state?: any;
};

const RouteMemberEditForm = ({ state }: Props) => {
  // const { state }: { state: RouteMemberListType } = useLocation();
  const { setLoading } = useLoading();
  const pathState = useLocation();
  const memberAssignment = pathState.state?.memAssign;
  const member = state?.member;
  const routeMember = pathState.state?.routeMember;
  const [shift, setShift] = useState(routeMember?.shiftTypeId);
  const [isAddForm, setIsAddForm] = useState(false);
  const [pickUpRouteId, setPickUpRouteId] = useState(
    routeMember?.pickUpRouteId
  );
  // const [dropRouteId, setDropRouteId] = useState(state.dropRouteId);
  const [pickupRoutes, setPickupRoutes] = useState<pickUpRouteDetailsType[]>();
  // const [dropRoutes, setDropRoutes] = useState<pickUpRouteDetailsType[]>();
  const [seatSelectError, setSeatSelectError] = useState("");
  const [pickShow, setPickShow] = useState({
    show: false,
    id: "",
    shiftId: "",
  });
  // const [dropShow, setDropShow] = useState({ show: false, id: "" });
  const tooltip = <Tooltip id={`tooltip`}>Reassign </Tooltip>;
  const tooltip1 = <Tooltip id={`tooltip`}>Assign </Tooltip>;

  const loadMemberAssignment = useCallback(async (reload?: boolean) => {
    setLoading(true);
    try {
      // if (pathState.state.routeMember) {
      //   state.routeMember = pathState.state.routeMember;
      // }
      if (reload === undefined) {
        setPickUpRouteId(routeMember?.pickUpRouteId);
        setShift(routeMember?.shiftTypeId);
      }
      const mem = await axiosInstance.get(
        `/master/getOneMemberAssignmentByMem/${member?._id}`
      );
      const memAssigned = mem.data.AssignedMember;
      pathState.state.memAssign = memAssigned;
      const res = await axiosInstance.get(
        `/dropDown/getOneMemberAssignmentByMember?memberId=${member?._id}&shiftTypeId=""`
      );

      const dropRouteDetails = res.data.DropRouteDetails;
      const pickUpRouteDetails = res.data
        .PickUpRouteDetails as pickUpRouteDetailsType[];
      setPickupRoutes(pickUpRouteDetails);
      setLoading(false);
      await loadSeatCounts("", pickUpRouteDetails, dropRouteDetails);
      // return { memAssignment, dropRouteDetails, pickUpRouteDetails };
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, []);

  const loadSeatCounts = async (
    shiftTypeId: string,
    pickupRoutes: any,
    dropRoutes: any
  ) => {
    try {
      const res = await axiosInstance.post(
        `/master/getSeatCount?shiftTypeId=${shiftTypeId}`,
        { pickupRoutes, dropRoutes }
      );
      const x = [];
      const y = [];
      for (let i = 0; i < pickupRoutes.length; i++) {
        x.push({
          ...pickupRoutes[i],
          occupied: res.data.resp?.pickRouteSeats[i],
        });
      }
      for (let i = 0; i < dropRoutes.length; i++) {
        y.push({
          ...dropRoutes[i],
          occupied: res.data.resp?.dropRouteSeats[i],
        });
      }
      setPickupRoutes(x);
      // setDropRoutes(y);
    } catch (error) {}
  };

  const isSeatMatch = async (
    routeId: string,
    sNo: string,
    isPickUp: boolean,
    shiftTypeId: string
  ) => {
    try {
      setLoading(true);
      const res = await axiosInstance.get(
        `/master/seatMatch?shiftTypeId=${shiftTypeId}&routeId=${routeId}&seatNo=${sNo}&isPickUp=${isPickUp}`
      );
      setLoading(false);
      return res.data.resp === undefined && sNo !== "" && sNo !== "0";
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  useEffect(() => {
    if (
      member === undefined ||
      !memberAssignment ||
      routeMember === undefined
    ) {
      // nav("../addForm/routeAdd?edit=2", {
      //   state: { memberAssign: state.memAssign, member: state.member },
      // });
      setIsAddForm(true);
    } else {
      setIsAddForm(false);
    }

    loadMemberAssignment();
  }, [pathState.state, isAddForm]);
  console.log(routeMember);
  console.log(pickUpRouteId, "pik");
  console.log(shift, "shift");

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      pickSeatNo: routeMember?.pickSeatNo ?? "",
    },
    onSubmit: async ({ pickSeatNo }) => {
      try {
        // if (pickupRoutes?.find(x => x.routeId === pickUpRouteId)?.seatNoRequired && (pickSeatNo === undefined || pickSeatNo === "")) {
        //   warnToast("Select Pick up seat number");
        //   return;
        // }
        // if (
        //   !state.isSame &&
        //   (dropRouteId === "" || dropSeatNo === undefined || dropSeatNo === "")
        // ) {
        //   warnToast("Select Drop seat number");
        //   return;
        // }

        // let formData;
        // if (!state.isSame) {
        //   formData = {
        //     dropSeatNo,
        //     pickSeatNo,
        //     pickUpRouteId,
        //     dropRouteId,
        //   };
        // } else {
        // if (
        //   shiftTypeId === "" ||
        //   pickUpRouteId === "" ||
        //   (pickupRoutes?.find((x) => x.routeId === pickUpRouteId)
        //     ?.seatNoRequired &&
        //     (pickSeatNo === undefined || pickSeatNo === ""))
        // ) {
        //   return;
        // }
        setLoading(true);
        const formData = {
          shiftTypeId: shift,
          dropSeatNo: pickSeatNo,
          pickSeatNo,
          pickUpRouteId: pickUpRouteId,
          dropRouteId: pickUpRouteId,
        };
        // }
        const id = routeMember?._id;
        const res = await axiosInstance.put(
          `/master/updateRouteMember/${id}`,
          formData
        );
        if (res.data === "Ok") {
          successToast();
          routeMember.shiftTypeId = shift;
          // routeMember.pick = shift;

          await loadMemberAssignment(true);
        }
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    },
  });

  const condition = (routeId: string, shiftId: string) => {
    return (
      (routeId === pickUpRouteId && shiftId === routeMember?.shiftTypeId) ||
      (routeId === routeMember.pickUpRouteId &&
        shiftId === routeMember?.shiftTypeId)
    );
  };

  return (
    <>
      {isAddForm ? (
        <RouteMemberAddForm isFromEdit={true} setIsAddForm={setIsAddForm} />
      ) : (
        <div className="formBg">
          <form onSubmit={formik.handleSubmit}>
            <div className="justify-content-between align-items-center d-flex my-3">
              <span>Assignment Details</span>
            </div>
            <ul className="profileList">
              <li>
                <span>Member :</span>
                {member?.name}
              </li>
              <li>
                <span>Area :</span>
                {member?.area}
              </li>
              <li>
                <span>PIN Code :</span>
                {member?.pinCode}
              </li>
              <li>
                <span>Address :</span>
                {member?.address}
              </li>
              <li>
                <span>Location :</span>
                {memberAssignment?.pickUpLoc}
              </li>
              <li>
                <span>Seat Number :</span>
                {routeMember?.pickSeatNo === "" ? 0 : routeMember?.pickSeatNo}
              </li>
            </ul>

            <Modal
              show={pickShow.show}
              onHide={() => {
                setPickUpRouteId(routeMember?.pickUpRouteId);
                setShift(routeMember?.shiftTypeId);
                setPickShow({ ...pickShow, show: false });
                setSeatSelectError("");
                formik.values.pickSeatNo = "";
              }}
            >
              <Modal.Body>
                <label style={{ width: "100%" }}>
                  <input
                    id="pickSeatNo"
                    name="pickSeatNo"
                    className="form-control"
                    type="number"
                    onChange={formik.handleChange}
                    value={formik.values.pickSeatNo}
                    placeholder="Enter seat number for Pick route"
                  />
                  {formik.values.pickSeatNo && (
                    <span
                      className="top-placeholder"
                      style={{ backgroundColor: "white" }}
                    >
                      Seat Number
                    </span>
                  )}
                  {seatSelectError !== "" && (
                    <span className="text-danger">{seatSelectError}</span>
                  )}
                </label>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="primary"
                  onClick={async () => {
                    if (
                      !(await isSeatMatch(
                        pickShow.id,
                        String(formik.values.pickSeatNo),
                        true,
                        pickShow.shiftId
                      )) &&
                      formik.values.pickSeatNo !== routeMember?.pickSeatNo
                    ) {
                      // setSeatNo(formik.values.pickSeatNo);
                      setSeatSelectError("Already seat assigned.");
                    } else {
                      setSeatSelectError("");
                      setPickShow({ ...pickShow, show: false });
                      formik.handleSubmit();
                      routeMember.pickSeatNo = formik.values.pickSeatNo;
                    }
                  }}
                >
                  Submit
                </Button>
                <Button
                  variant="secondary"
                  onClick={() => {
                    setPickUpRouteId(routeMember.pickUpRouteId);
                    setShift(routeMember.shiftTypeId);
                    //   setSeatNo(undefined);
                    setPickShow({ ...pickShow, show: false });
                    formik.values.pickSeatNo = "";
                    setSeatSelectError("");
                  }}
                >
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
            {/* <Modal
            show={dropShow.show}
            onHide={() => {
              //   setDropSeatNo("");
              setDropShow({ ...dropShow, show: false });
              setSeatSelectError("");
              formik.values.dropSeatNo = "";
            }}
          >
            <Modal.Body>
              <input
                id="dropSeatNo"
                name="dropSeatNo"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.dropSeatNo}
                className="form-control"
                type="text"
                placeholder="Enter seat number for Drop route"
              />
              {seatSelectError !== "" && (
                <span className="text-danger">{seatSelectError}</span>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="primary"
                onClick={async () => {
                  if (
                    !(await isSeatMatch(
                      dropShow.id,
                      String(formik.values.dropSeatNo),
                      false
                    )) &&
                    formik.values.dropSeatNo !== state.dropSeatNo
                  ) {
                    setSeatSelectError("Please choose another seat");
                    // setDropSeatNo(formik.values.dropSeatNo);
                  } else {
                    setDropRouteId(dropShow.id);
                    setSeatSelectError("");
                    setDropShow({ ...dropShow, show: false });
                  }
                }}
              >
                Submit
              </Button>
              <Button
                variant="secondary"
                onClick={() => {
                  formik.values.dropSeatNo = "";
                  //   setDropSeatNo("");
                  setDropShow({ ...dropShow, show: false });
                  setSeatSelectError("");
                }}
              >
                Close
              </Button>
            </Modal.Footer>
          </Modal> */}
            <div className="justify-content-between align-items-center d-flex my-3">
              <span>List Of Pickup Routes You Can Assign.</span>
            </div>
            <table className="table">
              <thead>
                <tr>
                  <th className="text-center">Shift</th>
                  <th className="text-center">Route</th>
                  <th className="text-center">Vehicle Number</th>
                  <th className="text-center">Total Seats</th>
                  <th className="text-center">Available Seats</th>
                  <th className="text-center">Occupied Seats</th>
                  <th className="text-center">Assign</th>
                </tr>
              </thead>
              <tbody>
                {pickupRoutes?.map(
                  (
                    {
                      vehicleNo,
                      routeId,
                      routeName,
                      totalSeats,
                      shiftId,
                      shiftName,
                      vehicleRegId,
                      seatNoRequired,
                      occupied,
                    },
                    index
                  ) => {
                    return (
                      <tr key={`${routeId}${vehicleNo}`}>
                        <>
                          <td className="text-center">{shiftName}</td>
                          <td className="text-center">{routeName}</td>
                          <td className="text-center">{vehicleNo}</td>
                          <td className="text-center">{totalSeats}</td>
                          <td className="text-center">
                            {occupied && seatNoRequired
                              ? Number(totalSeats) - occupied
                              : totalSeats}
                          </td>
                          <td className="text-center">
                            {seatNoRequired ? occupied ?? 0 : 0}
                          </td>
                          <td className="text-center">
                            {condition(routeId, shiftId) ? (
                              <OverlayTrigger
                                placement="right"
                                overlay={tooltip}
                              >
                                <span
                                  onClick={(e) => {
                                    setPickUpRouteId(routeId);
                                    setShift(shiftId);
                                    if (!seatNoRequired) {
                                      formik.values.pickSeatNo = "";
                                      formik.handleSubmit();
                                      routeMember.pickSeatNo = "";
                                    } else {
                                      setPickShow({
                                        id: routeId,
                                        show: true,
                                        shiftId,
                                      });
                                    }
                                  }}
                                  style={{ cursor: "pointer" }}
                                >
                                  <Done />
                                </span>
                              </OverlayTrigger>
                            ) : Number(totalSeats) - occupied === 0 ? (
                              <OverlayTrigger
                                placement="right"
                                overlay={tooltip1}
                              >
                                <span
                                  style={{
                                    cursor: "not-allowed",
                                    pointerEvents: "none",
                                  }}
                                >
                                  <Edit />
                                </span>
                              </OverlayTrigger>
                            ) : (
                              <OverlayTrigger
                                placement="right"
                                overlay={tooltip1}
                              >
                                <span
                                  onClick={(e) => {
                                    setPickUpRouteId(routeId);
                                    setShift(shiftId);
                                    if (!seatNoRequired) {
                                      formik.values.pickSeatNo = "";
                                      formik.handleSubmit();
                                      routeMember.pickSeatNo = "";
                                    } else {
                                      setPickShow({
                                        id: routeId,
                                        show: true,
                                        shiftId,
                                      });
                                    }
                                  }}
                                  style={{ cursor: "pointer" }}
                                >
                                  <Edit />
                                </span>
                              </OverlayTrigger>
                            )}
                          </td>
                        </>
                      </tr>
                    );
                  }
                )}
              </tbody>
            </table>
            {/* {!state.isSame && (
            <>
              <div className="justify-content-between align-items-center d-flex my-3">
                <span>List of drop routes you can assign.</span>
              </div>
              <table className="table">
                <thead>
                  <tr>
                    <th className="text-center">Route</th>
                    <th className="text-center">Vehicle Number</th>
                    <th className="text-center">Total Seats</th>
                    <th className="text-center">Available Seats</th>
                    <th className="text-center">Occupied Seats</th>
                    <th className="text-center">Assign</th>
                  </tr>
                </thead>
                <tbody>
                  {dropRoutes?.map(
                    ({
                      licensePlateNo,
                      routeId,
                      routeName,
                      totalSeats,
                      vehicleRegId,
                      occupied,
                      seatNoRequired,
                    }) => {
                      return (
                        <tr key={routeId}>
                          <>
                            <td className="text-center">{routeName}</td>
                            <td className="text-center">{licensePlateNo}</td>
                            <td className="text-center">{totalSeats}</td>
                            <td className="text-center">
                              {occupied
                                ? Number(totalSeats) - occupied
                                : totalSeats}
                            </td>
                            <td className="text-center">{occupied ?? 0}</td>
                            <td className="text-center">
                              {state.dropRouteId !== routeId ? (
                                <Edit
                                  type="button"
                                  onClick={(e) => {
                                    if (!seatNoRequired) {
                                      setDropRouteId(routeId);
                                      //   setDropSeatNo("");
                                    } else {
                                      setDropShow({ id: routeId, show: true });
                                    }
                                  }}
                                />
                              ) : (
                                <Eye
                                  type="button"
                                  onClick={(e) => {
                                    if (!seatNoRequired) {
                                      setDropRouteId(routeId);
                                      //   setDropSeatNo("");
                                    } else {
                                      setDropShow({ id: routeId, show: true });
                                    }
                                  }}
                                />
                              )}
                            </td>
                          </>
                        </tr>
                      );
                    }
                  )}
                </tbody>
              </table>
            </>
          )} */}
            <div className="col-md-12 mb-3 text-center">
              {/* <SubmitBtn
              errors={formik.errors}
              clickFunc={() => {
                if (pickUpRouteId === "") warnToast("Select pick up route");
                if (
                  pickupRoutes?.find((x) => x.routeId === pickUpRouteId)
                    ?.seatNoRequired &&
                  (formik.values.pickSeatNo === undefined ||
                    formik.values.pickSeatNo === "")
                ) {
                  warnToast("Select Pick up seat number");
                  return;
                }
              }}
            /> */}
              <CancelBtn path=".." />
            </div>
          </form>
        </div>
      )}
    </>
  );
};

export default RouteMemberEditForm;
