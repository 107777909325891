import React, { memo, useState } from "react";
import { AttendantLeaveDataType } from "../../../types/attendantLeaveTypes";
import { ReactComponent as Approved } from "assets/images/Done.svg";
import { ReactComponent as Dissmiss } from "assets/images/Dismiss.svg";
import axiosInstance from "axiosInstance";
import { Button, Modal } from "react-bootstrap";
import { drvImage, formatDate } from "constants/Constants";
// import { ReactComponent as Approved } from "assets/images/approve-20.svg";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

type Props = {
  x: AttendantLeaveDataType;
  loadAttendantLeave: (
    pageNum: number,
    sortParam: string,
    searchKeyword: string
  ) => any;
};

const AttendantLeaveItem = ({ x, loadAttendantLeave }: Props): JSX.Element => {
  const [show, setShow] = useState(false);
  const [type, setType] = useState<string>("");

  const handleClose = () => setShow(false);

  const onSubmit = async (id: string) => {
    try {
      let formData = {};
      if (type === "approve") {
        formData = {
          isApproved: true,
          isRejected: 1,
          approvedDate: new Date(),
        };
      } else if (type === "reject") {
        formData = {
          isRejected: 2,
          approvedDate: new Date(),
        };
      }
      const res = await axiosInstance.patch(
        `/master/updateAttendantLeave/${id}`,
        formData
      );
      if (res) {
        loadAttendantLeave(1, "attendantName", "");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const apporovetooltip = <Tooltip id={`tooltip-${x._id}`}>Approved</Tooltip>;
  const rejecttooltip = <Tooltip id={`tooltip-${x._id}`}>Reject</Tooltip>;
  return (
    <>
      <tr key={`${x._id}${Math.random()}`}>
        <td>
          <i className="userImg">
            <img
              src={`${drvImage}${x.photo !== "" ? x.photo : "Default.png"}`}
              alt="DriverPic"
            />
          </i>
        </td>
        <td>{x.attendantName}</td>
        <td>{x.vehicleName}</td>
        <td>{x.routeName}</td>
        <td>{formatDate(x.startDate)}</td>
        <td>{formatDate(x.endDate)}</td>
        <td>
          {(() => {
            switch (x.isRejected) {
              case 0:
                return "Pending";
              case 1:
                return "Approved";
              case 2:
                return "Rejected";
              default:
                return "";
            }
          })()}
        </td>
        <td>{formatDate(x.createdAt)}</td>
        <td className={`text-center ${x.isRejected === 0 ? "pointer" : ""}`}>
          <OverlayTrigger placement="bottom" overlay={rejecttooltip}>
            <Dissmiss
              className={`Approved_Size ${
                x.isRejected !== 0 ? "disabled" : ""
              }`}
              id={x._id}
              type="button"
              onClick={(e) => {
                setType("reject");
                setShow(true);
              }}
            />
          </OverlayTrigger>
          <OverlayTrigger placement="bottom" overlay={apporovetooltip}>
            <Approved
              className={`Approved_Size ${
                x.isRejected !== 0 ? "disabled" : ""
              }`}
              id={x._id}
              type="button"
              onClick={(e) => {
                setType("approve");
                setShow(true);
              }}
            />
          </OverlayTrigger>
        </td>
      </tr>

      <Modal show={show} onHide={handleClose}>
        <Modal.Body>
          Are You Sure For Leave {type === "approve" ? "Approved" : "Reject"}?
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => {
              setShow(false);
              onSubmit(x._id);
            }}
          >
            Submit
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default memo(AttendantLeaveItem);
