export const validPaths = [
  "profile",
  "/dashboard",
  "/dashboard/SosList",
  "/dashboard/vehicleLatLog",
  "memberDetails",
  "paymentReport",
  "memberWaitReport",
  "basicMemberPickupOffDropOffReport",
  "basicDriverPickupOffDropOffReport",
  "drivertracking",
  "report",
  "routeDetailsReport",
  "areaDetailsReport",
  "driverComplaint",
  "vehiclePucReport",
  "vehicleInsuranceReport",
  "waitReport",
  "memGetPassReport",
  "driverLeaveReport",
  "sosReport",
  "speedReports",
  "paymentReport",
  "driverPickupOffDropOffReport",
  "memberPickupOffDropOffReport",
  "basicDriverONOFFReport",
];
