import { useEffect, useCallback, useState, memo } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axiosInstance from "axiosInstance";
import { useLocation, useNavigate } from "react-router-dom";
import RouteMasterType from "types/route.types";

import { useLoading } from "Context/LoadingContext";
import { AreaDDLType } from "types/areaMasterTypes";
import { AreaDivisionDDL } from "types/areaDivisionTypes";
import { LocationDataTypeDDL } from "types/locationMasterType";
import { RouteLocationType } from "types/routeLocationTypes";
import toastValidation, {
  successToast,
  warnToast,
} from "constants/toastConfig";
import SubmitBtn from "components/Buttons/Submit";
import CancelBtn from "components/Buttons/Cancel";

type Props = {};

const RouteLocationEditForm = (props: Props) => {
  const nav = useNavigate();
  const { state } = useLocation();
  const [routeLocation, setRouteLocation] = useState<RouteLocationType>();

  const { setLoading } = useLoading();

  const [area, setArea] = useState<AreaDDLType[]>([]);
  const [pin, setPin] = useState("");
  const [areaName, setAreaName] = useState("");

  const [division, setDivision] = useState<AreaDivisionDDL[]>([]);
  const [divId, setDivId] = useState("");

  const [location, setLocation] = useState<LocationDataTypeDDL[]>([]);
  const [locId, setLocId] = useState("");

  const loadAreas = useCallback(async () => {
    setLoading(true);
    try {
      const res = await axiosInstance.get(`/dropDown/getArea`);
      const areas = res.data.Areas;
      setLoading(false);
      return areas;
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, []);

  const loadAreaCode = useCallback(async (pinCode: string) => {
    setLoading(true);
    try {
      const res = await axiosInstance.get(
        `/dropDown/getAreaDivision?pinCode=${pinCode}`
      );
      setLoading(false);
      return res.data.division;
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, []);

  const loadLocation = useCallback(async (areaCode: string) => {
    setLoading(true);
    try {
      const res = await axiosInstance.get(
        `/dropDown/getLocationByDivision?areaCode=${areaCode}`
      );
      setLoading(false);
      return res.data.locationNames;
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, []);

  const getOneRouteLocation = useCallback(async () => {
    try {
      setLoading(true);
      //fetching one routeLocation
      const res = await axiosInstance.get(
        `/master/getOneRouteLocation/${state.routeLocId}`
      );
      const routeLocation = res.data.routeLocation[0];
      setRouteLocation(routeLocation);

      //updating the area DropDown
      const area = await loadAreas();
      if (area === undefined) {
        setArea([]);
        setPin("");
      } else {
        const updatedAreaList = updateAlist(area, routeLocation.pinCode);
        setArea(updatedAreaList);
        setPin(routeLocation.pinCode);
      }

      //updating the area DropDown
      const pickDiv = await loadAreaCode(routeLocation.pinCode);
      if (pickDiv === undefined) {
        setDivision([]);
        setDivId("");
      } else {
        const updatedDivList = updateList(pickDiv, routeLocation.divisionId);
        setDivision(updatedDivList as AreaDivisionDDL[]);
        setDivId(updatedDivList[0]._id);
      }

      //updating the location dropDown
      const pickLoc = await loadLocation(routeLocation.divisionId);
      if (pickLoc === undefined) {
        setLocation([]);
        setLocId("");
      } else {
        const newPickUpLocList = updateLList(pickLoc, routeLocation.locationId);
        setLocation(newPickUpLocList as LocationDataTypeDDL[]);
        setLocId(newPickUpLocList[0].locationId);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }, []);

  const updateLList = (lList: any, Id: string) => {
    const newLoc = [...lList];
    const record = newLoc?.find(
      (x: LocationDataTypeDDL) => x?.locationId === Id
    );
    const index = newLoc.indexOf(record as LocationDataTypeDDL);
    newLoc.splice(index, 1);
    newLoc.splice(0, 0, record as LocationDataTypeDDL);
    return newLoc;
  };
  const updateList = (
    list: RouteMasterType[] | AreaDivisionDDL[],
    id: string | undefined
  ) => {
    const newList = [...list];
    const record = newList?.find(
      (x: RouteMasterType | AreaDivisionDDL) => x?._id === id
    );
    const index = newList.indexOf(record as RouteMasterType | AreaDivisionDDL);
    newList.splice(index, 1);
    newList.splice(0, 0, record as RouteMasterType | AreaDivisionDDL);
    return newList;
  };

  const updateAlist = (AList: AreaDDLType[], Id: string) => {
    const newArea = [...AList];
    const record = newArea?.find((x: AreaDDLType) => x?.pinCode === Id);

    const index = newArea.indexOf(record as AreaDDLType);
    newArea.splice(index, 1);
    newArea.splice(0, 0, record as AreaDDLType);
    return newArea;
  };

  useEffect(() => {
    getOneRouteLocation();
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      pickupTime: routeLocation?.pickupTime,
      dropTime: routeLocation?.dropTime,
      active: true,
    },

    validationSchema: Yup.object().shape({
      pickupTime: Yup.string().required("Pickup Time is Required"),
      dropTime: Yup.string().required("Drop Time is Required"),
    }),

    onSubmit: async ({ pickupTime, dropTime, active }) => {
      try {
        setLoading(true);
        const formData = {
          routeId: state._id,
          locationId: locId,
          area: areaName,
          pinCode: pin,
          divisionId: divId,
          pickupTime: pickupTime,
          dropTime: dropTime,
          isActive: active,
        };
        const res = await axiosInstance.put(
          `/master/updateRouteLocation/${state.routeLocId}`,
          formData
        );
        if (res.status === 202) {
          setLoading(false);
          warnToast(res.data);
        }
        const responseMessage = res.data.message;
        if (responseMessage === "Ok") {
          successToast();
          setLoading(false);
          const item = state;
          nav(
            {
              pathname: "/routeMaster/editForm",
              search: "?index=2",
            },
            { state: item }
          );
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    },
  });

  const areaDropDown = async (m: string) => {
    setPin(m);
    setDivId("");
    setLocId("");
    if (m !== "") {
      const div = await loadAreaCode(m);
      if (div !== undefined) {
        setDivision(div);
      } else {
        setDivision([]);
      }
    } else {
      setDivision([]);
      setLocation([]);
    }
  };
  const divisionDropDown = async (n: string) => {
    setDivId(n);
    setLocId("");
    if (n !== "") {
      const loc = await loadLocation(n);
      if (loc !== undefined) {
        setLocation(loc);
      } else {
        setLocation([]);
      }
    } else {
      setLocation([]);
    }
  };
  const settingAreaName = async (pincode: string) => {
    const name = area.find((x) => {
      return x.pinCode === pincode;
    })?.areaName;
    setAreaName(name as string);
  };

  return (
    <>
      <div className="formBg">
        <form className="row align-items-center" onSubmit={formik.handleSubmit}>
          <label htmlFor="area" className="col-md-6 mb-3">
            <span className="top-placeholder">Area*</span>

            <select
              className="form-control"
              id="area"
              onChange={async (e) => {
                areaDropDown(e.target.value);
                settingAreaName(e.target.value);
              }}
            >
              {pin === "" && <option value="">Select Area</option>}
              {area.map(({ _id, areaName, pinCode }) => {
                return (
                  <option key={_id} value={pinCode}>
                    {`${areaName} (${pinCode})`}
                  </option>
                );
              })}
            </select>
          </label>
          <label className="col-md-6 mb-3" htmlFor="areaCode">
            <span className="top-placeholder">Division*</span>

            <select
              className="form-control"
              id="areaCode"
              name="areaCode"
              onChange={async (e) => {
                divisionDropDown(e.target.value);
              }}
            >
              {divId === "" && <option value="">Select Division</option>}
              {division.map(({ _id, areaCode }) => {
                return (
                  <option key={_id} value={_id}>
                    {areaCode}
                  </option>
                );
              })}
            </select>
          </label>
          <label className="col-md-6 mb-3" htmlFor="location">
            <span className="top-placeholder">Location*</span>

            <select
              className="form-control"
              id="location"
              name="location"
              onChange={async (e) => {
                setLocId(e.target.value);
              }}
            >
              {locId === "" && <option value="">Select Location</option>}
              {location.map(({ locationId, locationName }) => {
                return (
                  <option key={locationId} value={locationId}>
                    {locationName}
                  </option>
                );
              })}
            </select>
          </label>
          <label className="col-md-6 mb-3" htmlFor="pickupTime">
            <input
              className="form-control session-time"
              id="pickupTime"
              name="pickupTime"
              type="time"
              // placeholder="Pickup Time"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.pickupTime}
              // onFocus={(e) => (e.target.type = "time")}
              onKeyDown={(e) => {
                e.preventDefault();
              }}
            />
            <span className="top-placeholder">Pickup Time*</span>
          </label>
          <label className="col-md-6 mb-3" htmlFor="dropTime">
            <input
              className="form-control session-time"
              id="dropTime"
              name="dropTime"
              type="time"
              // placeholder="Drop Time"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.dropTime}
              // onFocus={(e) => (e.target.type = "time")}
              onKeyDown={(e) => {
                e.preventDefault();
              }}
            />
            <span className="top-placeholder">Drop Time*</span>
          </label>

          <div className="col-md-6 mb-3">
            <label htmlFor="active" className="custCheck">
              Active
              <input
                type="checkbox"
                id="active"
                name="active"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                checked={formik.values.active}
              />
              <span className="checkmark"></span>
            </label>
          </div>

          <div className="col-md-12 mb-3 text-center">
            <SubmitBtn
              errors={formik.errors}
              clickFunc={() => {
                if (divId === "") warnToast("Select Division");
                if (locId === "") warnToast("Select Location");
                toastValidation(formik.errors);
              }}
            />
            <button
              className="btn btn-primary"
              onClick={() => {
                const item = state;
                nav(
                  {
                    pathname: "/routeMaster/editForm",
                    search: "?index=2",
                  },
                  { state: item }
                );
              }}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default memo(RouteLocationEditForm);
