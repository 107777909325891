import React, { memo } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axiosInstance from "axiosInstance";
import { useLocation, useNavigate } from "react-router-dom";
import { useLoading } from "Context/LoadingContext";
import SubmitBtn from "components/Buttons/Submit";
import CancelBtn from "components/Buttons/Cancel";
import { successToast, warnToast } from "constants/toastConfig";

type Props = {};

const AttendantForm = (props: Props) => {
  const { setLoading } = useLoading();
  const nav = useNavigate();
  const location = useLocation();
  const formik = useFormik({
    validateOnMount: true,
    initialValues: {
      name: "",
      address: "",
      contactNo: "",
      photo: "",
      description: "",
      isActive: true,
      note: "",
      details: "",
      code: "",
    },
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .min(1, "Attendant Name is Too Short!")
        .max(30, "Attendant Name is Too Long!")
        .required("Attendant Name is Required"),
      contactNo: Yup.string()
        .min(10, "Contact Number is Not Valid!")
        .max(10, "Contact Number is Not Valid!")
        .required("Contact Number is Required"),
      address: Yup.string()
        .min(1, "Address is Too Short!")
        .max(300, "Address is Too Long!")
        .required("Address is Required"),
      description: Yup.string().max(300, "Description is Too Long!"),
      photo: Yup.mixed()
        .test("fileSize", "Photo Size is Too High", (value) => {
          if (!value) return true;
          return value && value.size <= 52428800; // 50MB in bytes
        })
        .test(
          "fileType",
          "Please Enter photo in jpg, jpeg, png Extention",
          (value) => {
            if (!value) return true;
            return value && /(\.jpg|\.jpeg|\.png)$/i.test(value.name);
          }
        ),
      isActive: Yup.boolean().default(true),
    }),
    onSubmit: async ({
      name,
      contactNo,
      address,
      photo,
      description,
      isActive,
      note,
      details,
      code,
    }) => {
      try {
        setLoading(true);
        const formData = new FormData();
        formData.append("name", name);
        formData.append("contactNo", String(contactNo));
        formData.append("address", address);
        formData.append("photo", photo);
        formData.append("description", description);
        formData.append("isActive", String(isActive));
        formData.append("note", note);
        formData.append("details", details);
        formData.append("code", code);
        const res = await axiosInstance.post("/master/addAttendant", formData);
        const responseMessage = res.data;
        if (res.status === 202) {
          setLoading(false);
          warnToast(responseMessage);
        }
        if (responseMessage === "Ok") {
          successToast();
          setLoading(false);
          nav("/Attendant");
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    },
  });
  return (
    <>
      <div className="formBg">
        <form onSubmit={formik.handleSubmit}>
          <div className=" row align-items-center">
            <label className="col-md-6 mb-3" htmlFor="name">
              <input
                className={`form-control ${
                  formik.errors.name && formik.submitCount > 0
                    ? "empty-form"
                    : ""
                }`}
                id="name"
                name="name"
                type="text"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.name}
                placeholder="Attendant Name"
              />

              <span className="top-placeholder">Attendant Name*</span>
            </label>
            <label className="col-md-6 mb-3" htmlFor="contactNo">
              <input
                className={`form-control ${
                  formik.errors.contactNo && formik.submitCount > 0
                    ? "empty-form"
                    : ""
                }`}
                id="contactNo"
                name="contactNo"
                type="number"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.contactNo}
                placeholder="Contact Number"
              />

              <span className="top-placeholder">Contact Number*</span>
            </label>
            <label className="col-md-6 mb-3" htmlFor="address">
              <input
                className={`form-control ${
                  formik.errors.address && formik.submitCount > 0
                    ? "empty-form"
                    : ""
                }`}
                id="address"
                name="address"
                type="text"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.address}
                placeholder="Address"
              />

              <span className="top-placeholder">Address*</span>
            </label>
            <label className="col-md-6 mb-3" htmlFor="photo">
              <input
                className="form-control uploadPhoto form-control-lg"
                id="photo"
                name="photo"
                type="file"
                onBlur={formik.handleBlur}
                onChange={(event) => {
                  const file =
                    event.currentTarget.files && event.currentTarget.files[0];
                  formik.setFieldValue("photo", file || "");
                }}
                placeholder="Enter photo"
              />
            </label>
            <label className="col-md-12 mb-3" htmlFor="description">
              <textarea
                className="form-control"
                id="description"
                name="description"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.description}
                placeholder="Attendant Description"
              ></textarea>

              <span className="top-placeholder">Attendant Description</span>
            </label>
            <div className="col-md-3 mb-3">
              <label className="custCheck">
                Active
                <input
                  type="checkbox"
                  id="active"
                  name="active"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  checked={formik.values.isActive}
                />
                <span className="checkmark"></span>
              </label>
            </div>
          </div>
          <div className="col-md-12 mb-3 text-center">
            <SubmitBtn errors={formik.errors} />
            {(location.pathname === "/attendant/attendantForm" ||
              location.pathname === "/attendant/attendantEdit") && (
              <CancelBtn path=".." />
            )}
          </div>
        </form>
      </div>
    </>
  );
};

export default memo(AttendantForm);
