import axiosInstance from "axiosInstance";
import { useLoading } from "Context/LoadingContext";
import { useFormik } from "formik";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { MemberAssignmentDDLType } from "types/memberAssignment";
import { ReactComponent as Edit } from "assets/images/edit.svg";
import { Modal, Button } from "react-bootstrap";
import { successToast, warnToast } from "constants/toastConfig";
import CancelBtn from "components/Buttons/Cancel";
import { ReactComponent as Done } from "assets/images/circleDone.svg";
import { useConfig } from "Context/ConfigContext";

type Props = {
  isFromEdit?: boolean;
  setIsAddForm?: any;
};

export type pickUpRouteDetailsType = {
  vehicleNo: string;
  routeId: string;
  routeName: string;
  totalSeats: string;
  vehicleRegId: string;
  seatNoRequired: boolean;
  occupied: number;
  shiftName: string;
  shiftId: string;
};

const RouteMemberAddForm = ({ isFromEdit, setIsAddForm }: Props) => {
  const { rfidCardCheck } = useConfig();
  const nav = useNavigate();
  const { setLoading } = useLoading();
  // const [shift, setShift] = useState<ShiftDDL[]>([]);
  const [shiftId, setShiftId] = useState("");

  const state = useLocation().state;
  const member = state.member;
  const memberAssignment = state.memAssign;
  // const dropSeatRef = useRef<HTMLInputElement>(null);
  const pickSeatRef = useRef<HTMLInputElement>(null);
  const [pickUpRouteId, setPickUpRouteId] = useState("");
  // const [dropRouteId, setDropRouteId] = useState("");
  const [memberAssign, setMemberAssign] = useState<MemberAssignmentDDLType[]>();
  const [pickupRoutes, setPickupRoutes] = useState<pickUpRouteDetailsType[]>();
  // const [dropRoutes, setDropRoutes] = useState<pickUpRouteDetailsType[]>();
  const [seatNo, setSeatNo] = useState<undefined | string>(undefined);
  // const [dropSeatNo, setDropSeatNo] = useState<undefined | string>(undefined);
  const [seatSelectError, setSeatSelectError] = useState("");
  const [pickShow, setPickShow] = useState({
    show: false,
    id: "",
    total: 0,
    shiftId: "",
  });
  // const [dropShow, setDropShow] = useState({ show: false, id: "", total: 0 });
  // const [shiftCount, setShiftCount] = useState<boolean>();

  const loadMemberAssignment = useCallback(
    async (shiftTypeId: string, reload?: boolean) => {
      setLoading(true);
      try {
        const mem = await axiosInstance.get(
          `/master/getOneMemberAssignmentByMem/${state?.member?._id}`
        );
        const memAssigned = mem.data.AssignedMember;
        state.memAssign = memAssigned;
        const res = await axiosInstance.get(
          `/dropDown/getOneMemberAssignmentByMember?memberId=${state.member._id}&shiftTypeId=${shiftTypeId}`
        );
        const memAssignment = res.data.MemberAssignment;
        const dropRouteDetails = res.data.DropRouteDetails;
        const pickUpRouteDetails = res.data
          .PickUpRouteDetails as pickUpRouteDetailsType[];
        setLoading(false);
        setPickupRoutes(pickUpRouteDetails);
        if (reload === undefined) {
          if (pickUpRouteDetails.length === 1) {
            if (!pickUpRouteDetails[0].seatNoRequired) {
              setPickUpRouteId(pickUpRouteDetails[0].routeId);
              setSeatNo("");
            }
          }
        }
        setMemberAssign(memAssignment);
        // return { memAssignment, dropRouteDetails, pickUpRouteDetails };
        await loadSeatCounts("", pickUpRouteDetails, dropRouteDetails);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    },
    []
  );

  const loadSeatCounts = async (
    shiftTypeId: string,
    pickupRoutes: any,
    dropRoutes: any
  ) => {
    try {
      const res = await axiosInstance.post(
        `/master/getSeatCount?shiftTypeId=${shiftTypeId}`,
        { pickupRoutes, dropRoutes }
      );
      const x = [];
      const y = [];
      for (let i = 0; i < pickupRoutes.length; i++) {
        x.push({
          ...pickupRoutes[i],
          occupied: res.data.resp?.pickRouteSeats[i],
        });
      }
      for (let i = 0; i < dropRoutes.length; i++) {
        y.push({
          ...dropRoutes[i],
          occupied: res.data.resp?.dropRouteSeats[i],
        });
      }
      setPickupRoutes(x);
      // setDropRoutes(y);
    } catch (error) {}
  };

  const isSeatMatch = async (
    shiftTypeId: string,
    routeId: string,
    sNo: string,
    isPickUp: boolean
  ) => {
    try {
      setLoading(true);
      const res = await axiosInstance.get(
        `/master/seatMatch?shiftTypeId=${shiftTypeId}&routeId=${routeId}&seatNo=${sNo}&isPickUp=${isPickUp}`
      );
      setLoading(false);
      return res.data.resp === undefined && sNo !== "" && sNo !== "0";
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (state === null) {
      nav("../..");
    } else {
      // loadShift();
      loadMemberAssignment("");
    }
  }, []);

  const formik = useFormik({
    validateOnMount: true,
    initialValues: {},
    onSubmit: async () => {
      try {
        setLoading(true);
        let formData = {
          shiftTypeId: shiftId,
          departmentId: state.member.departmentId,
          categoryId: state.member.categoryId || "",
          typeId: state.member.typeId || "",
          pickUpRouteId: pickUpRouteId,
          memberId: state.member._id,
          memberAssignmentId: state.memAssign._id,
          pickSeatNo: String(seatNo) ?? "",
          dropSeatNo: String(seatNo) ?? "",
          dropRouteId: pickUpRouteId,
        };
        // if (
        //   memberAssign !== undefined &&
        //   memberAssign?.length !== 0 &&
        //   memberAssign[0].isSame
        // ) {
        const res = await axiosInstance.post(
          "/master/addRouteMember",
          formData
        );
        state.routeMember = res.data.result;
        if (res.data.statusCode === 201) {
          warnToast(res.data.message);
          if (res.data.result === 1) {
            nav({ pathname: "/feesSection", search: "?index=0" });
          } else if (res.data.result === 2) {
            nav({ pathname: "/feesSection", search: "?index=1" });
          } else if (res.data.result === 3) {
            nav({ pathname: "/feesSection", search: "?index=1" });
          } else if (res.data.result === 4) {
            nav({ pathname: "/feesSection", search: "?index=1" });
          } else if (res.data.result === 5) {
            nav({ pathname: "/feesSection", search: "?index=2" });
          } else if (res.data.result === 7) {
            nav({ pathname: "/memberMaster" });
          }
        }
        if (res.data.statusCode === 200) {
          if (res.data.message !== "Ok") {
            warnToast(res.data.message);
          }
          successToast();
          if (isFromEdit) {
            setLoading(false);
            setIsAddForm(false);
            return;
            // nav("../../editForm", { state });
          } else if (rfidCardCheck) nav("../cardAdd", { state: state.member });
          else nav("../..");
          loadMemberAssignment("", true);
          setLoading(false);
        }
        // } else {
        //   console.log(dropSeatNo, "dropSeatNo");

        //   //Two api calls with different form data
        //   const res1 = await axiosInstance.post("/master/addRouteMember", {
        //     ...formData,
        //     dropSeatNo: dropSeatNo,
        //   });

        //   if (res1.data.statusCode === 201) {
        //     warnToast(res1.data.message);
        //     if (res1.data.result === 1) {
        //       nav("/feesConfig");
        //     } else if (res1.data.result === 2) {
        //       nav("/feesKm");
        //     } else if (res1.data.result === 3) {
        //       nav("/feesArea");
        //     } else if (res1.data.result === 4) {
        //       nav("/feesByVehicle");
        //     } else if (res1.data.result === 5) {
        //       nav("/feesStructure");
        //     }
        //   }
        //   if (res1.data.statusCode === 200) {
        //     successToast();
        //     warnToast(res1.data.message);
        //     nav("..");
        //   }
        // }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
      setLoading(false);
    },
  });

  return (
    <>
      <div className="formBg">
        <form onSubmit={formik.handleSubmit}>
          {/* <ul className="profileList">
            <li>
              <span>Member :</span>
              {member?.name}
            </li>
            <li>
              <span>Area :</span>
              {member?.area}
            </li>
            <li>
              <span>Pincode :</span>
              {member?.pinCode}
            </li>
            <li>
              <span>Address :</span>
              {member?.address}
            </li>
            <li>
              <span>Location :</span>
              {memberAssignment?.pickUpLoc}
            </li>
          </ul> */}
          {/* <div className="row align-items-center">
            <label className="col-md-6 mb-3" htmlFor="shift">
              <select
                className="form-control"
                id="shift"
                name="shift"
                onChange={async (e) => {
                  setShiftId(e.target.value);
                }}
              >
                {!shiftCount && <option value="">Select Shift</option>}
                {shift.map((item) => {
                  return (
                    <option value={item._id} key={item._id}>
                      {item.name}
                    </option>
                  );
                })}
              </select>
            </label>
            <label htmlFor="department" className="col-md-6 mb-3">
              <select
                className="form-control"
                id="department"
                onChange={(e) => {
                  depDropDown(e.target.value);
                }}
              >
                <option value="" selected>
                  Select Department
                </option>
                {department.map(({ _id, departmentName }) => {
                  return (
                    <option key={_id} value={_id}>
                      {departmentName}
                    </option>
                  );
                })}
              </select>
            </label>
            <label htmlFor="category" className="col-md-6 mb-3">
              <select
                className="form-control"
                id="category"
                onChange={(e) => {
                  catDropDown(e.target.value);
                }}
              >
                <option value="" selected>
                  Select Category
                </option>
                {category.length !== 0 &&
                  category.map(({ _id, name }) => {
                    return (
                      <option key={_id} value={_id}>
                        {name}
                      </option>
                    );
                  })}
              </select>
            </label>
            <label htmlFor="type" className="col-md-6 mb-3">
              <select
                className="form-control"
                id="type"
                onChange={(e) => {
                  typeDropDown(e.target.value);
                }}
                <option value="" selected>
                  Select Sub Category
                </option>
                {type.length !== 0 &&
                  type.map(({ _id, name }) => {
                    return (
                      <option key={_id} value={_id}>
                        {name}
                      </option>
                    );
                  })}
              </select>
            </label>
            <label htmlFor="member" className="col-md-6 mb-3">
              <select
                className="form-control"
                id="member"
                onChange={async (e) => {
                  setMemberId(e.target.value);
                  setPickUpRouteId("");
                  setDropRouteId("");
                  const res = await loadMemberAssignment(
             <li>
              <span>Drop Location :</span>
              {memberAssign !== undefined &&
                `${
                  memberAssign[0]?.dropLocation ?? "No location selected yet"
                }`}
            </li> *
          </div> */}

          <Modal
            show={pickShow.show}
            onHide={() => {
              setPickShow({ ...pickShow, show: false });
              setSeatSelectError("");
              setSeatNo(undefined);
            }}
          >
            <Modal.Body>
              <label style={{ width: "100%" }}>
                <input
                  ref={pickSeatRef}
                  className="form-control"
                  type="number"
                  placeholder="Enter seat number for Pick route"
                />
                {seatSelectError !== "" && (
                  <span className="text-danger">{seatSelectError}</span>
                )}
                <span
                  style={{ backgroundColor: "white" }}
                  className="top-placeholder"
                >
                  Seat Number
                </span>
              </label>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="primary"
                onClick={async () => {
                  setSeatNo(pickSeatRef.current?.value);
                  if (
                    memberAssign !== undefined &&
                    memberAssign?.length !== 0 &&
                    memberAssign[0].isSame
                  ) {
                    if (
                      (await isSeatMatch(
                        pickShow.shiftId,
                        pickShow.id,
                        String(pickSeatRef.current?.value),
                        true
                      )) &&
                      Number(pickSeatRef.current?.value) <= pickShow.total &&
                      (await isSeatMatch(
                        pickShow.shiftId,
                        pickShow.id,
                        String(pickSeatRef.current?.value),
                        false
                      ))
                    ) {
                      setSeatSelectError("");
                      setPickShow({ ...pickShow, show: false });
                      formik.handleSubmit();
                    } else {
                      setSeatSelectError("Already seat assigned.");
                    }
                  } else {
                    if (
                      (await isSeatMatch(
                        pickShow.shiftId,
                        pickShow.id,
                        String(pickSeatRef.current?.value),
                        true
                      )) &&
                      Number(pickSeatRef.current?.value) <= pickShow.total
                    ) {
                      setSeatSelectError("");
                      setPickShow({ ...pickShow, show: false });
                      formik.handleSubmit();
                    } else {
                      setSeatSelectError("Already seat assigned.");
                    }
                  }
                }}
              >
                Submit
              </Button>
              <Button
                variant="secondary"
                onClick={() => {
                  setSeatNo(undefined);
                  setPickShow({ ...pickShow, show: false });
                  setSeatSelectError("");
                }}
              >
                Close
              </Button>
            </Modal.Footer>
          </Modal>
          {/* <Modal
            show={dropShow.show}
            onHide={() => {
              setDropSeatNo("");
              setDropShow({ ...dropShow, show: false });
              setSeatSelectError("");
            }}
          >
            <Modal.Body>
              <input
                ref={dropSeatRef}
                className="form-control"
                type="text"
                placeholder="Enter seat number for Drop route"
              />
              {seatSelectError !== "" && (
                <span className="text-danger">{seatSelectError}</span>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="primary"
                onClick={async () => {
                  if (
                    (await isSeatMatch(
                      shiftId,
                      dropShow.id,
                      String(dropSeatRef.current?.value),
                      false
                    )) &&
                    dropSeatRef.current?.value !== undefined &&
                    Number(dropSeatRef.current?.value) <= dropShow.total
                  ) {
                    setDropRouteId(dropShow.id);
                    setSeatSelectError("");
                    setDropShow({ ...dropShow, show: false });
                    setDropSeatNo(dropSeatRef.current?.value);
                  } else {
                    setSeatSelectError("Please choose another seat");
                  }
                }}
              >
                Submit
              </Button>
              <Button
                variant="secondary"
                onClick={() => {
                  setDropSeatNo("");
                  setDropShow({ ...dropShow, show: false });
                  setSeatSelectError("");
                }}
              >
                Close
              </Button>
            </Modal.Footer>
          </Modal> */}
          <div className="justify-content-between align-items-center d-flex my-3">
            <span>List of pickUp routes you can assign.</span>
          </div>
          <table className="table">
            <thead>
              <tr>
                <th className="text-center">Shift</th>
                <th className="text-center">Route</th>
                <th className="text-center">Vehicle Number</th>
                <th className="text-center">Total Seats</th>
                <th className="text-center">Available Seats</th>
                <th className="text-center">Occupied Seats</th>
                <th className="text-center">Assign</th>
              </tr>
            </thead>
            <tbody>
              {pickupRoutes?.map(
                (
                  {
                    vehicleNo,
                    routeId,
                    routeName,
                    totalSeats,
                    shiftId: shift,
                    shiftName,
                    vehicleRegId,
                    seatNoRequired,
                    occupied,
                  },
                  index
                ) => {
                  return (
                    <tr key={`${routeId}${vehicleNo}`}>
                      <>
                        <td className="text-center">{shiftName}</td>
                        <td className="text-center">
                          {routeName ?? pickupRoutes[index - 1].routeName}
                        </td>
                        <td className="text-center">{vehicleNo}</td>
                        <td className="text-center">{totalSeats}</td>
                        <td className="text-center">
                          {occupied
                            ? Number(totalSeats) - occupied
                            : totalSeats}
                        </td>
                        <td className="text-center">{occupied ?? 0}</td>
                        <td className="text-center">
                          {routeId === pickUpRouteId ? (
                            <Done
                              type="button"
                              onClick={(e) => {
                                setShiftId(shift);
                                setPickUpRouteId(routeId);
                                if (!seatNoRequired) {
                                  setSeatNo("");
                                  formik.handleSubmit();
                                } else {
                                  setPickShow({
                                    id: routeId,
                                    show: true,
                                    total: Number(totalSeats),
                                    shiftId: shift,
                                  });
                                }
                              }}
                            />
                          ) : (
                            <Edit
                              type="button"
                              onClick={(e) => {
                                setPickUpRouteId(routeId);
                                setShiftId(shift);
                                if (!seatNoRequired) {
                                  setSeatNo("");
                                  formik.handleSubmit();
                                } else {
                                  setPickShow({
                                    id: routeId,
                                    show: true,
                                    total: Number(totalSeats),
                                    shiftId: shift,
                                  });
                                }
                              }}
                            />
                          )}
                        </td>
                      </>
                    </tr>
                  );
                }
              )}
            </tbody>
          </table>
          {/* {memberAssign !== undefined &&
            memberAssign?.length !== 0 &&
            !memberAssign[0].isSame && (
              <>
                <div className="justify-content-between align-items-center d-flex my-3">
                  <span>List of drop routes you can assign.</span>
                </div>
                <table className="table">
                  <thead>
                    <tr>
                      <th className="text-center">Route</th>
                      <th className="text-center">Vehicle Number</th>
                      <th className="text-center">Total Seats</th>
                      <th className="text-center">Available Seats</th>
                      <th className="text-center">Occupied Seats</th>
                      <th className="text-center">Assign</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dropRoutes?.map(
                      ({
                        licensePlateNo,
                        routeId,
                        routeName,
                        totalSeats,
                        vehicleRegId,
                        occupied,
                        seatNoRequired,
                      }) => {
                        return (
                          <tr key={routeId}>
                            <>
                              <td className="text-center">{routeName}</td>
                              <td className="text-center">{licensePlateNo}</td>
                              <td className="text-center">{totalSeats}</td>
                              <td className="text-center">
                                {occupied
                                  ? Number(totalSeats) - occupied
                                  : totalSeats}
                              </td>
                              <td className="text-center">{occupied ?? 0}</td>
                              <td className="text-center">
                                {dropRouteId === routeId ? (
                                  <Done />
                                ) : (
                                  <Edit
                                    type="button"
                                    onClick={(e) => {
                                      if (!seatNoRequired) {
                                        setDropRouteId(routeId);
                                        setDropSeatNo("");
                                      } else {
                                        setDropShow({
                                          id: routeId,
                                          show: true,
                                          total: Number(totalSeats),
                                        });
                                      }
                                    }}
                                  />
                                )}
                              </td>
                            </>
                          </tr>
                        );
                      }
                    )}
                  </tbody>
                </table>
              </>
            )} */}

          <div className="col-md-12 mb-3 text-center">
            {/* <SubmitBtn
              errors={formik.errors}
              clickFunc={() => {
                // if (shiftId === "") warnToast("Select shift");
                // if (depId === "") warnToast("Select Department");
                // if (category.length !== 0 && catId === "")
                //   warnToast("Select Category");
                // if (type.length !== 0 && typeId === "")
                //   warnToast("Select Sub Category");
                // if (memberId === "") warnToast("Select Member");

                if (pickUpRouteId === "") warnToast("Select Pick Up Route");
                // if (
                //   memberAssign !== undefined &&
                //   memberAssign?.length !== 0 &&
                //   !memberAssign[0].isSame &&
                //   dropRouteId === ""
                // ) {
                //   warnToast("Select Drop Route");
                //   // if (
                //   //   dropRoutes?.find((x) => x.routeId === dropRouteId)
                //   //     ?.seatNoRequired === true &&
                //   //   dropSeatNo === undefined
                //   // ) {
                //   //   warnToast("Select Drop Seat Number");
                //   // }
                // }
                if (
                  pickupRoutes?.find((x) => x.routeId === pickUpRouteId)
                    ?.seatNoRequired &&
                  (seatNo === undefined || seatNo === "")
                )
                  warnToast("Select Pick up Seat number");
              }}
            /> */}
            <CancelBtn path={isFromEdit ? ".." : "../.."} />
          </div>
        </form>
      </div>
    </>
  );
};

export default RouteMemberAddForm;
