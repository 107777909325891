import {
  convertToMonthYearFormat,
  formatDate,
  currencySymbol,
} from "constants/Constants";
import React from "react";

type Props = {
  item: any;
};

const MemberFeesDetailsListItems = ({ item }: Props) => {
  return (
    <>
      <tr key={Math.random()}>
        <td>{item.memberName}</td>
        <td>
          {item?.termName?.charAt(0).toUpperCase() + item?.termName?.slice(1)}
        </td>
        <td>{`${convertToMonthYearFormat(
          item.startMonth
        )} - ${convertToMonthYearFormat(item.endMonth)}`}</td>
        <td>{`${formatDate(item.startDate)} - ${formatDate(item.dueDate)}`}</td>
        <td>
          {currencySymbol}
          {item.termFees}
        </td>
        <td>
          {currencySymbol}
          {item?.paidFees ?? "0.00"}
        </td>
        <td>
          {item?.payType
            ? item?.payType?.charAt(0).toUpperCase() + item?.payType?.slice(1)
            : ""}
        </td>
        <td>{item.referenceId}</td>
        <td>{item.paymentStatus === 0 ? "Pending" : "Paid"}</td>
      </tr>
    </>
  );
};

export default MemberFeesDetailsListItems;
