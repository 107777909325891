import axiosInstance from "axiosInstance";
import React, { useCallback, useState, useEffect, memo, useMemo } from "react";
import { useLoading } from "Context/LoadingContext";
import { useTable, usePagination } from "react-table";
import "../../../assets/styles/style.css";
import RouteMasterType from "types/route.types";
import {
  convertTo12HourFormat,
  downloadTableAsPDF,
  downloadTableAsPrint,
  exportPDF,
  exportToExcel,
  formatDateForFile,
} from "constants/Constants";
import AddSearch from "components/AddSearchComponent";
import department from "../department";
import NotFound from "components/NotFound";
import { downloadExcel } from "constants/ReportFun";

type Props = {};

const MemberRouteDetailsReportList = (props: Props): JSX.Element => {
  const [data, setData] = useState([]);
  // const [route, setRoute] = useState<RouteMasterType[]>([]);
  // const [rouId, setRouId] = useState("");
  const [search, setSearch] = useState<string>("");
  const [searchQuery, setSearchQuery] = useState<string>("");
  const { setLoading } = useLoading();
  const [notFound, setNotFound] = useState<boolean>(false);

  // const RouteList = useCallback(async () => {
  //   try {
  //     setLoading(true);
  //     const res = await axiosInstance.get(`/dropDown/getRoute`);
  //     const rList = res.data.Routes;
  //     setRoute(rList);
  //     setLoading(false);
  //   } catch (error) {
  //     console.log(error);
  //     setLoading(false);
  //   }
  // }, []);

  const loadReports = useCallback(
    async (searchKeyword: string) => {
      try {
        setLoading(true);
        const res = await axiosInstance.get(
          `/report/membersRouteDetails?search=${searchKeyword}`
        );
        setData(res.data.resp);
        setLoading(false);
        setNotFound(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
        setNotFound(true);
      }
    },
    [data]
  );

  // useEffect(() => {
  //   RouteList();
  // }, []);

  const handleSearch = () => {
    setData([]);
    setSearchQuery(search);
    loadReports(search);
  };

  useEffect(() => {
    loadReports(searchQuery);
  }, [searchQuery]);

  const columns = useMemo(
    () => [
      {
        Header: "Shift",
        accessor: "shift",
      },
      {
        Header: "Route",
        accessor: "routeName",
      },
      {
        Header: "Vehicle No",
        accessor: "vehicleNo",
      },
      {
        Header: "Driver",
        accessor: "driver",
      },
      {
        Header: "Area",
        accessor: "area",
      },
      {
        Header: "Division",
        accessor: "division",
      },
      {
        Header: "Location",
        accessor: "location",
      },
      {
        Header: "Pickup Time",
        accessor: (row: any) => convertTo12HourFormat(row.time),
      },
      {
        Header: "Department",
        accessor: "department",
      },
      {
        Header: "Category",
        accessor: "category",
      },
      {
        Header: "Member",
        accessor: "memberName",
      },
      {
        Header: "MemberId",
        accessor: "memberId",
      },
    ],
    []
  );

  const download = async () => {
    try {
      downloadExcel(
        [
          {
            id: "shift",
            name: "Shift",
          },
          {
            id: "routeName",
            name: "Route",
          },
          {
            id: "vehicleNo",
            name: "Vehicle No",
          },
          {
            id: "driver",
            name: "Driver",
          },
          {
            id: "area",
            name: "Area",
          },
          {
            id: "division",
            name: "Division",
          },
          {
            id: "location",
            name: "Location",
          },
          {
            id: "time",
            name: "Pickup Time",
            isTime: true,
          },
          {
            id: "department",
            name: "Department",
          },
          {
            id: "category",
            name: "Category",
          },
          {
            id: "memberName",
            name: "Member",
          },
          {
            id: "memberId",
            name: "MemberId",
          },
        ],
        data === undefined ? [] : data,
        "Route_Details",
        "route_Details"
      );
    } catch (error) {
      return {};
    }
  };
  const todayDate = new Date();
  const dateForFile = formatDateForFile(todayDate.toISOString());
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex, pageSize },
    setPageSize,
  }: any = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 } as any,
    },
    usePagination
  );

  return (
    <>
      <div className="pageTitle justify-content-between align-items-center d-flex">
        <span>Route Members Details Report</span>
      </div>
      <button
        onClick={() =>
          downloadTableAsPDF(columns, data, `Route_Details-${dateForFile}.pdf`)
        }
        className="btn btn-primary mb-4"
      >
        Export to PDF
      </button>
      <button
        onClick={() => {
          download();
        }}
        className="btn btn-primary mb-4"
      >
        Export to Excel
      </button>
      <button
        onClick={() => downloadTableAsPrint(columns, data)}
        className="btn btn-primary mb-4"
      >
        Print
      </button>

      <AddSearch
        handleSearch={handleSearch}
        search={search}
        setSearch={setSearch}
        placeholder="Route Name or Area or Department or Member Name or MemberId"
      />
      <table {...getTableProps()} id="report-table" className="tableForReport">
        <thead>
          {headerGroups.map((headerGroup: any) => (
            <tr {...headerGroup.getHeaderGroupProps()} className="trForReport">
              {headerGroup.headers.map((column: any) => (
                <th {...column.getHeaderProps()} className="thForReport">
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row: any, i: any) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} className="trForReport">
                {row.cells.map((cell: any) => {
                  return (
                    <td {...cell.getCellProps()} className="tdForReport">
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      {notFound && (
        <div className="mt-4">
          {" "}
          <NotFound />
        </div>
      )}
      <div className="pagination m-4 gap-2">
        <button
          onClick={() => gotoPage(0)}
          disabled={!canPreviousPage}
          className="btnBackg"
        >
          {"<<"}
        </button>{" "}
        <button
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
          className="btnBackg"
        >
          {"<"}
        </button>{" "}
        <button
          onClick={() => nextPage()}
          disabled={!canNextPage}
          className="btnBackg"
        >
          {">"}
        </button>{" "}
        <button
          onClick={() => gotoPage(pageCount - 1)}
          disabled={!canNextPage}
          className="btnBackg"
        >
          {">>"}
        </button>{" "}
        <span className="mx-3">
          Page{" "}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{" "}
        </span>
        <select
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30, 40, 50, 60].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>
    </>
  );
};

export default memo(MemberRouteDetailsReportList);
